import React from 'react';

export const InputEmail = ({
  isInvalid,
  value,
  onChange,
  onKeyUp,
  onKeyPress,
  className = ''
}) => {
  return (
    <>
      {isInvalid && (
        <p className="form__error__message">Enter a valid email address</p>
      )}
      <label htmlFor="email">
        <input
          id="email"
          type="email"
          className={className}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          required
          autoComplete="off"
          placeholder="Enter email"
        />
      </label>
    </>
  );
};
