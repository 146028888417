import gql from 'graphql-tag';
import * as ApolloReactHooks from '@apollo/react-hooks';
import { fetchUserDetails } from 'api/fetchUserDetails';

export const getAuthStateQuery = gql`
  query GetAuthState {
    authState @client {
      state
    }
  }
`;

export const setAuthStateQuery = gql`
  mutation SetAuthState($state: String!) {
    setAuthState(status: $state) @client {
      state
    }
  }
`;

export function useGetAuthStateQuery(baseOptions) {
  return ApolloReactHooks.useQuery(getAuthStateQuery, baseOptions);
}

export function useSetAuthStateMutation(baseOptions) {
  return ApolloReactHooks.useMutation(setAuthStateQuery, baseOptions);
}

export const setAuthState = (client, state) =>
  client.mutate({
    mutation: setAuthStateQuery,
    variables: { state },
    refetchQueries: [
      {
        query: fetchUserDetails,
        variables: { time: new Date().getTime() }
      }
    ]
  });
