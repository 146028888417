import React, { useState } from 'react';
import { imageRoot } from 'utils/utils';
import { legalSignature } from 'utils/constants';
import { Modal } from 'components/Modal/Modal';
import { toast } from 'react-toastify';
import { Contact } from 'components/Form/Contact';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const [modalState, setModalState] = useState(false);

  const showModal = () => setModalState(true);
  const hideModal = () => setModalState(false);

  const handleFormError = err => {
    if (err.errorMessage) {
      toast.error(err.errorMessage);
    } else {
      hideModal();
      toast.error('Something went wrong! Please try again later.');
    }
  };

  const handleFormSuccess = () => {
    hideModal();
    toast.success('Thank you for your interest!');
  };

  return (
    <footer className="footer">
      <section className="footer__offices__container">
        <h2 className="footer__offices footer__header">Offices</h2>
        <ul className="footer__addresses">
          <li>
            <p className="footer__address footer__offices__country">
              United Kingdom
            </p>
            <p className="footer__address">St Clare House, 30 Minories</p>
            <p className="footer__address">London, EC3N 1DD</p>
            <p className="footer__address">+44 (0)207 921 9980</p>
          </li>
          <li>
            <p className="footer__address footer__offices__country">
              United States Of America
            </p>
            <p className="footer__address">1725 | Street NW, Suite 300</p>
            <p className="footer__address">Washington D.C., WA 20006</p>
            <p className="footer__address">+1 202 261 3567</p>
          </li>
        </ul>
      </section>
      <section className="footer__contact">
        <h2 className="footer__feedback footer__header" onClick={showModal}>
          Feedback
        </h2>
        <h2 className="footer__contact-us footer__header" onClick={showModal}>
          Contact Us
        </h2>
        <Modal
          open={modalState}
          onClose={hideModal}
          contentLabel="Contact form modal">
          <Contact onError={handleFormError} onSuccess={handleFormSuccess} />
        </Modal>
        <h2 className="footer__social footer__header">
          Social Media
          <a
            href="https://www.linkedin.com/company/paymentscompliance/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-icon">
            <img src={imageRoot('icon-linkedin.svg')} alt="LinkedIn" />
          </a>
          <a
            href="https://twitter.com/PayCompliance"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__social-icon">
            <img src={imageRoot('icon-twitter.svg')} alt="Twitter" />
          </a>
        </h2>
        <div className="terms__condition__container">
          <Link to="/terms-conditions">Terms & Conditions</Link>
          <br />
          <Link to="/anti-slavery-policy">Anti-Slavery Policy</Link>
        </div>
        <div className="break" />
      </section>
      <p className="footer__legal">{legalSignature}</p>
    </footer>
  );
};
