import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { fetchUserDetails } from 'api/fetchUserDetails';
import {
  setCompanyJurisdictions,
  setCompanyProxy,
  setAccess
} from 'api/client/permissions';
import { matomoSiteId, matomoHost, sentryEnable } from 'utils/constants';
import { Nav } from 'components/Nav/Nav';
import { Preloader } from 'components/common/Preloader';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { setInitials } from 'api/client/initials';
import NotFound from 'components/Errors/NotFound';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { LoggedOutLayoutRoute } from './LoggedOutLayoutRoute';
import { AppLayoutRoute } from './AppLayoutRoute';
import { PrivateRoute } from './PrivateRoute';
import { LazyRoute } from './LazyRoute';
import { WebpIsSupported } from '../../utils/utils';

Modal.setAppElement('#root');

(async () => {
  if (await WebpIsSupported()) {
    document.querySelector('html').classList.add('webp');
  } else {
    document.querySelector('html').classList.add('no-webp');
  }
})();

const routes = [
  {
    path: '/',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "home" */
        'components/Landing/Home'
      )
    ),
    exact: true,
    private: true,
    title: 'Home'
  },
  {
    path: '/home',
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "home" */
        'components/Landing/Home'
      )
    ),
    exact: true,
    private: true,
    title: 'Home'
  },
  {
    path: '/search',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "search" */
        'components/Search/Search'
      )
    ),
    private: true,
    title: 'Search'
  },
  {
    path: '/article/:articleId',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "article" */
        'components/Article/Article'
      )
    ),
    private: true,
    title: 'Article'
  },
  {
    path: '/insights',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "articles" */
        'components/Articles/Articles'
      )
    ),
    private: true,
    title: 'Insights'
  },
  {
    path: '/regulatory_analysis',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "articles" */
        'components/Articles/Articles'
      )
    ),
    private: true,
    title: 'Regulatory Analysis'
  },
  {
    path: '/guide/:guideId',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "guides" */
        'components/Guides/Guides'
      )
    ),
    private: true,
    title: 'Guides'
  },
  {
    path: '/guide',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "guides" */
        'components/Guides/Guides'
      )
    ),
    private: true,
    title: 'Guides'
  },
  {
    path: '/rule/:ruleCollectionId/:type?/:typeId?',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "rules" */
        'components/Rules/Rules'
      )
    ),
    private: true,
    title: 'Rules'
  },
  {
    path: '/rule',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "rules" */
        'components/Rules/Rules'
      )
    ),
    private: true,
    title: 'Rules'
  },
  {
    path: '/requirement/:requirementId',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "requirements" */
        'components/Requirement/Requirement'
      )
    ),
    private: true,
    title: 'Requirement'
  },
  {
    path: '/requirement',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "requirements" */
        'components/Requirement/Table'
      )
    ),
    private: true,
    title: 'Table'
  },
  {
    path: '/regulatory_update/:regupdateId',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "reg-update" */
        'components/RegUpdate/RegUpdate'
      )
    ),
    private: true,
    title: 'Horizon Scanning'
  },
  {
    path: '/regulatory_updates/feeds',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "reg-updates-feed" */
        'components/RegUpdatesFeed/RegUpdatesFeed'
      )
    ),
    private: true,
    title: 'Horizon Scanning Feed'
  },
  {
    path: ['/settings/:option', '/settings'],
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "settings" */
        'components/Settings/Settings'
      )
    ),
    private: true,
    title: 'Settings'
  },
  {
    path: '/author/:authorId',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "author" */
        'components/Author/Author'
      )
    ),
    private: true,
    title: 'Author'
  },
  {
    path: [
      '/:vocabulary/:tagId/:parentName/:tagName',
      '/:vocabulary/:tagId/:tagName',
      '/:vocabulary/:tagId/'
    ],
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "term" */
        'components/Term/Term'
      )
    ),
    private: true,
    title: 'Term'
  },
  {
    path: '/tracker_topics',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "trackers" */
        'components/Trackers/Trackers'
      )
    ),
    private: true,
    title: 'Trackers'
  },
  {
    path: '/terms-conditions',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "trackers" */
        'components/BasicPage/TermsConditions'
      )
    ),
    private: true,
    title: 'Terms & conditions'
  },
  {
    path: '/anti-slavery-policy',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "trackers" */
        'components/BasicPage/AntiSlaveryPolicy'
      )
    ),
    private: true,
    title: 'Anti-Slavery Policy'
  },
  {
    path: '/vixio-admin',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "admin" */
        'components/Admin/Admin'
      )
    ),
    private: true,
    title: 'Admin'
  },
  {
    path: '/hsdashbaord',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "hsdashboard" */
        'components/HSDashboard/HSDashboard'
      )
    ),
    private: true,
    title: 'HSDashboard'
  },
  {
    path: '/hsdashbaordcomparison',
    sidebar: Nav,
    component: React.lazy(() =>
      import(
        /* webpackChunkName: "hsdashboardcomparison" */
        'components/HSDashboard/HSDashboardComparison'
      )
    ),
    private: true,
    title: 'HSDashboard'
  },
  {
    path: '/',
    component: NotFound,
    private: false,
    title: 'Not Found'
  }
];

const AppLoggedIn = () => {
  return (
    <Switch>
      {routes.map(route => {
        const RouterComponent = route.private ? PrivateRoute : LazyRoute;
        const Component = route.component;
        return (
          <RouterComponent
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={Component}
          />
        );
      })}
    </Switch>
  );
};

toast.configure({
  position: 'bottom-left',
  autoClose: 5000,
  newestOnTop: true,
  closeOnClick: false,
  pauseOnVisibilityChange: true,
  draggable: true,
  pauseOnHover: false,
  hideProgressBar: true
});

let time = new Date().getTime();
window.setTimeout(() => {
  time = new Date().getTime();
}, 300000);
const AppUserDetails = () => {
  const client = useApolloClient();
  const {
    data: {
      currentUserContext: {
        fieldFirstName,
        fieldLastName,
        fieldCompany,
        fieldSalesforceId,
        reverseFieldUserSubscription: { entities: permissions } = {}
      } = {}
    } = {},
    loading
  } = useQuery(fetchUserDetails, { variables: { time } });

  useEffect(() => {
    // Rename to subscriptions
    setAccess(
      permissions,
      fieldCompany !== undefined && fieldCompany !== null
        ? fieldCompany.entity
        : undefined
    );
  }, [permissions, fieldCompany]);

  useEffect(() => {
    if (fieldCompany && fieldCompany.entity) {
      setCompanyProxy(fieldCompany.entity.fieldProxyEs === true);
      const ids = fieldCompany.entity.fieldJurisdictions.map(
        ({ entity: { entityId } }) => entityId
      );
      if (ids) {
        setCompanyJurisdictions(fieldCompany.entity.fieldAllJurisdictions, [
          ...ids
        ]);
      }
    }
  }, [fieldCompany]);

  useEffect(() => {
    const [firstInitial] = fieldFirstName || 'Ω';
    const [lastInitial] = fieldLastName || ' ';
    setInitials(client, `${firstInitial} ${lastInitial}`);
  }, [fieldFirstName, fieldLastName, client]);

  if (loading) {
    return <Preloader />;
  }

  if (fieldSalesforceId !== undefined) {
    const instance = createInstance({
      urlBase: `https://${matomoHost}`,
      siteId: matomoSiteId,
      userId: fieldSalesforceId
    });
    const userDetails = {
      fieldFirstName,
      fieldLastName,
      fieldCompany,
      permissions
    };
    return (
      <MatomoProvider value={instance}>
        <AppLayoutRoute component={AppLoggedIn} userDetails={userDetails} />
      </MatomoProvider>
    );
  }
  return <AppLayoutRoute component={AppLoggedIn} />;
};

export const App = () => {
  if (sentryEnable === '1') {
    Sentry.init({
      dsn:
        'https://7d72393d137d4b09af35e5266aa77a6e@o404146.ingest.sentry.io/5267482'
    });
  }

  const loggedOutComponent = React.lazy(() =>
    import(/* webpackChunkName: "logged-out" */ 'components/Landing/LoggedOut')
  );

  return (
    <Router>
      <Switch>
        <LoggedOutLayoutRoute
          path="/loggedout"
          component={loggedOutComponent}
        />
        <AppUserDetails />
      </Switch>
    </Router>
  );
};
