import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { client } from 'api/apolloClient';
import Cookie from 'js-cookie';
import { useDocumentListener } from 'components/common/useDocumentListener';
import { useGetInitialsQuery } from 'api/client/initials';

export const AccountOptions = () => {
  const { data, error, loading } = useGetInitialsQuery();
  const [isActive, toggleOptions] = useState(false);
  const menuRef = useRef(null);
  useDocumentListener(menuRef, toggleOptions);
  if (error || loading) return null;
  const {
    initials: { value: userInitials }
  } = data;
  const toggleAccountOptions = () => {
    toggleOptions(!isActive);
  };

  const logOut = () => {
    toggleOptions(!isActive);
    Cookie.remove('access_token');
    Cookie.remove('refresh_token');
    Cookie.remove('client_id');
    client.resetStore();
  };

  return (
    <div className="account-options" ref={menuRef}>
      <div
        className={`account-options__icon ${
          /[a-zA-Z]+/.test(userInitials) ? '' : 'account-options__omega'
        }`}
        onClick={toggleAccountOptions}>
        {userInitials}
      </div>
      <ul
        className={`account-options__selector${
          isActive ? ' account-options__selector--active' : ''
        }`}>
        {/* Profile tab temporarily hidden until further notice */}
        {/* <li className="account-options__selector__item">
          <Link
            className="account-options__selector__item__option button--primary"
            to={{
              pathname: '/settings/profile'
            }}
            >
            My Profile
          </Link>
        </li> */}
        {/* <li className="account-options__selector__item"> */}
        {/*  <Link */}
        {/*    className="account-options__selector__item__option button--primary" */}
        {/*    to={{ */}
        {/*      pathname: '/settings/settings' */}
        {/*    }}> */}
        {/*    Account Settings */}
        {/*  </Link> */}
        {/* </li> */}
        <li className="account-options__selector__item">
          <Link
            className="account-options__selector__item__option button--primary"
            to={{
              pathname: '/settings/favourites'
            }}>
            My Favourites
          </Link>
        </li>
        <li className="account-options__selector__item">
          <Link
            className="account-options__selector__item__option button--primary"
            to={{
              pathname: '/settings/bookmarks'
            }}>
            Bookmarks
          </Link>
        </li>
        <li className="account-options__selector__item">
          <Link
            className="account-options__selector__item__option button--primary"
            to={{
              pathname: '/settings/help'
            }}>
            Help
          </Link>
        </li>
        <li className="account-options__selector__item">
          <Link
            className="account-options__selector__item__option button--primary"
            to={{
              pathname: '/settings/password'
            }}>
            Change Password
          </Link>
        </li>

        <li className="account-options__selector__item">
          <button
            type="button"
            className="button button--primary button--white"
            onClick={logOut}>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  );
};
