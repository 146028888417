export const getRecentSearches = () => {
  return JSON.parse(localStorage.getItem('recentSearches'));
};

export const updateRecentSearch = query => {
  const recentSearches = getRecentSearches();

  if (recentSearches && recentSearches.includes(query)) {
    const newSearches = recentSearches.filter(stored => stored !== query);

    localStorage.setItem(
      'recentSearches',
      JSON.stringify([...newSearches, query])
    );
    return;
  }

  if (recentSearches) {
    const savedSearches =
      recentSearches.length > 3 ? recentSearches.slice(1, 4) : recentSearches;

    localStorage.setItem(
      'recentSearches',
      JSON.stringify([...savedSearches, query])
    );
  } else {
    localStorage.setItem('recentSearches', JSON.stringify([query]));
  }
};

export const getTaggedStatus = () => {
  return JSON.parse(localStorage.getItem('taggedStatus'));
};

export const updateTaggedStatus = status => {
  localStorage.setItem('taggedStatus', JSON.stringify(status));
};
