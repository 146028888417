import gql from 'graphql-tag';
import { client } from './apolloClient';

export const query = gql`
  query EnabledFilters {
    enabledFilters @client {
      __typename
      name
      count
      vid
    }
  }
`;

let previousPath = '';
export const updateEnabledFilters = aggregations => {
  if (previousPath !== window.location.pathname) {
    previousPath = window.location.pathname;
    let aggregation = [];
    Object.keys(aggregations).forEach(agg => {
      if (aggregations[agg].buckets.length) {
        const buckets = aggregations[agg].buckets.map(
          ({ key, doc_count: docCount }) => ({
            name: key,
            count: docCount,
            vid: agg,
            __typename: 'filter'
          })
        );
        aggregation = [...aggregation, ...buckets];
      }
    });

    client.writeData({
      data: {
        enabledFilters: [...aggregation]
      }
    });
  }
};

export const getEnabledFilters = () => {
  try {
    return client.readQuery({ query });
  } catch (error) {
    // if none exist
    return null;
  }
};

export const clearEnabledFilters = () => {
  updateEnabledFilters([]);
};
