import { useEffect } from 'react';

export const useDocumentListener = ({ current }, toggleOptions) => {
  const handleClickOutside = ({ target }) => {
    if (current && !current.contains(target)) {
      toggleOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
