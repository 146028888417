export default {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Url',
        possibleTypes: [
          {
            name: 'EntityCanonicalUrl'
          },
          {
            name: 'ExternalUrl'
          },
          {
            name: 'DefaultInternalUrl'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'BlockContent',
        possibleTypes: [
          {
            name: 'BlockContentJumbotronContent'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Entity',
        possibleTypes: [
          {
            name: 'User'
          },
          {
            name: 'Company'
          },
          {
            name: 'ContentModerationState'
          },
          {
            name: 'File'
          },
          {
            name: 'PathAlias'
          },
          {
            name: 'Subscription'
          },
          {
            name: 'SalesforceProduct'
          },
          {
            name: 'Consumer'
          },
          {
            name: 'Notification'
          },
          {
            name: 'PardotMail'
          },
          {
            name: 'PardotMailEvent'
          },
          {
            name: 'QuickFilter'
          },
          {
            name: 'IncomingMessage'
          },
          {
            name: 'SearchApiTask'
          },
          {
            name: 'UnexposedEntity'
          },
          {
            name: 'BlockContentJumbotronContent'
          },
          {
            name: 'FlaggingHidden'
          },
          {
            name: 'FlaggingTracked'
          },
          {
            name: 'MediaImage'
          },
          {
            name: 'NodeArticle'
          },
          {
            name: 'NodeAuthor'
          },
          {
            name: 'NodeBasicPage'
          },
          {
            name: 'NodeGuide'
          },
          {
            name: 'NodeRegulatoryUpdate'
          },
          {
            name: 'NodeRuleCollection'
          },
          {
            name: 'RuleBasic'
          },
          {
            name: 'RuleComplex'
          },
          {
            name: 'Oauth2TokenAccessToken'
          },
          {
            name: 'Oauth2TokenAuthCode'
          },
          {
            name: 'Oauth2TokenRefreshToken'
          },
          {
            name: 'TaxonomyTermAuthorities'
          },
          {
            name: 'TaxonomyTermCompanies'
          },
          {
            name: 'TaxonomyTermDateType'
          },
          {
            name: 'TaxonomyTermDocumentTypes'
          },
          {
            name: 'TaxonomyTermFeaturedTopics'
          },
          {
            name: 'TaxonomyTermJurisdictions'
          },
          {
            name: 'TaxonomyTermLocalDocumentType'
          },
          {
            name: 'TaxonomyTermProductsAndServices'
          },
          {
            name: 'TaxonomyTermRelationshipType'
          },
          {
            name: 'TaxonomyTermSpecialisms'
          },
          {
            name: 'TaxonomyTermTrackerTopics'
          },
          {
            name: 'MenuLinkContentMenuLinkContent'
          },
          {
            name: 'ParagraphAccordion'
          },
          {
            name: 'ParagraphAccordions'
          },
          {
            name: 'ParagraphContactDetails'
          },
          {
            name: 'ParagraphExistingContent'
          },
          {
            name: 'ParagraphMedia'
          },
          {
            name: 'ParagraphSectionContent'
          },
          {
            name: 'ParagraphSectionText'
          },
          {
            name: 'ParagraphText'
          },
          {
            name: 'ParagraphUpdate'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'TaxonomyTerm',
        possibleTypes: [
          {
            name: 'TaxonomyTermAuthorities'
          },
          {
            name: 'TaxonomyTermCompanies'
          },
          {
            name: 'TaxonomyTermDateType'
          },
          {
            name: 'TaxonomyTermDocumentTypes'
          },
          {
            name: 'TaxonomyTermFeaturedTopics'
          },
          {
            name: 'TaxonomyTermJurisdictions'
          },
          {
            name: 'TaxonomyTermLocalDocumentType'
          },
          {
            name: 'TaxonomyTermProductsAndServices'
          },
          {
            name: 'TaxonomyTermRelationshipType'
          },
          {
            name: 'TaxonomyTermSpecialisms'
          },
          {
            name: 'TaxonomyTermTrackerTopics'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          {
            name: 'NodeArticle'
          },
          {
            name: 'NodeAuthor'
          },
          {
            name: 'NodeBasicPage'
          },
          {
            name: 'NodeGuide'
          },
          {
            name: 'NodeRegulatoryUpdate'
          },
          {
            name: 'NodeRuleCollection'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'MenuLinkContent',
        possibleTypes: [
          {
            name: 'MenuLinkContentMenuLinkContent'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityOwnable',
        possibleTypes: [
          {
            name: 'Company'
          },
          {
            name: 'ContentModerationState'
          },
          {
            name: 'File'
          },
          {
            name: 'Subscription'
          },
          {
            name: 'SalesforceProduct'
          },
          {
            name: 'Consumer'
          },
          {
            name: 'Notification'
          },
          {
            name: 'QuickFilter'
          },
          {
            name: 'IncomingMessage'
          },
          {
            name: 'FlaggingHidden'
          },
          {
            name: 'FlaggingTracked'
          },
          {
            name: 'MediaImage'
          },
          {
            name: 'NodeArticle'
          },
          {
            name: 'NodeAuthor'
          },
          {
            name: 'NodeBasicPage'
          },
          {
            name: 'NodeGuide'
          },
          {
            name: 'NodeRegulatoryUpdate'
          },
          {
            name: 'NodeRuleCollection'
          },
          {
            name: 'RuleBasic'
          },
          {
            name: 'RuleComplex'
          },
          {
            name: 'ParagraphAccordion'
          },
          {
            name: 'ParagraphAccordions'
          },
          {
            name: 'ParagraphContactDetails'
          },
          {
            name: 'ParagraphExistingContent'
          },
          {
            name: 'ParagraphMedia'
          },
          {
            name: 'ParagraphSectionContent'
          },
          {
            name: 'ParagraphSectionText'
          },
          {
            name: 'ParagraphText'
          },
          {
            name: 'ParagraphUpdate'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityRevisionable',
        possibleTypes: [
          {
            name: 'Company'
          },
          {
            name: 'ContentModerationState'
          },
          {
            name: 'PathAlias'
          },
          {
            name: 'Subscription'
          },
          {
            name: 'BlockContentJumbotronContent'
          },
          {
            name: 'MediaImage'
          },
          {
            name: 'NodeArticle'
          },
          {
            name: 'NodeAuthor'
          },
          {
            name: 'NodeBasicPage'
          },
          {
            name: 'NodeGuide'
          },
          {
            name: 'NodeRegulatoryUpdate'
          },
          {
            name: 'NodeRuleCollection'
          },
          {
            name: 'RuleBasic'
          },
          {
            name: 'RuleComplex'
          },
          {
            name: 'TaxonomyTermAuthorities'
          },
          {
            name: 'TaxonomyTermCompanies'
          },
          {
            name: 'TaxonomyTermDateType'
          },
          {
            name: 'TaxonomyTermDocumentTypes'
          },
          {
            name: 'TaxonomyTermFeaturedTopics'
          },
          {
            name: 'TaxonomyTermJurisdictions'
          },
          {
            name: 'TaxonomyTermLocalDocumentType'
          },
          {
            name: 'TaxonomyTermProductsAndServices'
          },
          {
            name: 'TaxonomyTermRelationshipType'
          },
          {
            name: 'TaxonomyTermSpecialisms'
          },
          {
            name: 'TaxonomyTermTrackerTopics'
          },
          {
            name: 'MenuLinkContentMenuLinkContent'
          },
          {
            name: 'ParagraphAccordion'
          },
          {
            name: 'ParagraphAccordions'
          },
          {
            name: 'ParagraphContactDetails'
          },
          {
            name: 'ParagraphExistingContent'
          },
          {
            name: 'ParagraphMedia'
          },
          {
            name: 'ParagraphSectionContent'
          },
          {
            name: 'ParagraphSectionText'
          },
          {
            name: 'ParagraphText'
          },
          {
            name: 'ParagraphUpdate'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Media',
        possibleTypes: [
          {
            name: 'MediaImage'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityPublishable',
        possibleTypes: [
          {
            name: 'PathAlias'
          },
          {
            name: 'QuickFilter'
          },
          {
            name: 'BlockContentJumbotronContent'
          },
          {
            name: 'MediaImage'
          },
          {
            name: 'NodeArticle'
          },
          {
            name: 'NodeAuthor'
          },
          {
            name: 'NodeBasicPage'
          },
          {
            name: 'NodeGuide'
          },
          {
            name: 'NodeRegulatoryUpdate'
          },
          {
            name: 'NodeRuleCollection'
          },
          {
            name: 'TaxonomyTermAuthorities'
          },
          {
            name: 'TaxonomyTermCompanies'
          },
          {
            name: 'TaxonomyTermDateType'
          },
          {
            name: 'TaxonomyTermDocumentTypes'
          },
          {
            name: 'TaxonomyTermFeaturedTopics'
          },
          {
            name: 'TaxonomyTermJurisdictions'
          },
          {
            name: 'TaxonomyTermLocalDocumentType'
          },
          {
            name: 'TaxonomyTermProductsAndServices'
          },
          {
            name: 'TaxonomyTermRelationshipType'
          },
          {
            name: 'TaxonomyTermSpecialisms'
          },
          {
            name: 'TaxonomyTermTrackerTopics'
          },
          {
            name: 'MenuLinkContentMenuLinkContent'
          },
          {
            name: 'ParagraphAccordion'
          },
          {
            name: 'ParagraphAccordions'
          },
          {
            name: 'ParagraphContactDetails'
          },
          {
            name: 'ParagraphExistingContent'
          },
          {
            name: 'ParagraphMedia'
          },
          {
            name: 'ParagraphSectionContent'
          },
          {
            name: 'ParagraphSectionText'
          },
          {
            name: 'ParagraphText'
          },
          {
            name: 'ParagraphUpdate'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Rule',
        possibleTypes: [
          {
            name: 'RuleBasic'
          },
          {
            name: 'RuleComplex'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Paragraph',
        possibleTypes: [
          {
            name: 'ParagraphAccordion'
          },
          {
            name: 'ParagraphAccordions'
          },
          {
            name: 'ParagraphContactDetails'
          },
          {
            name: 'ParagraphExistingContent'
          },
          {
            name: 'ParagraphMedia'
          },
          {
            name: 'ParagraphSectionContent'
          },
          {
            name: 'ParagraphSectionText'
          },
          {
            name: 'ParagraphText'
          },
          {
            name: 'ParagraphUpdate'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Flagging',
        possibleTypes: [
          {
            name: 'FlaggingHidden'
          },
          {
            name: 'FlaggingTracked'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'Oauth2Token',
        possibleTypes: [
          {
            name: 'Oauth2TokenAccessToken'
          },
          {
            name: 'Oauth2TokenAuthCode'
          },
          {
            name: 'Oauth2TokenRefreshToken'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityDefinition',
        possibleTypes: [
          {
            name: 'EntityDefinitionType'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityDefinitionField',
        possibleTypes: [
          {
            name: 'EntityDefinitionFieldType'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'SearchAPIDocument',
        possibleTypes: [
          {
            name: 'GlobalSearchDoc'
          },
          {
            name: 'TaxonomyDoc'
          }
        ]
      },
      {
        kind: 'INTERFACE',
        name: 'EntityDescribable',
        possibleTypes: []
      },
      {
        kind: 'INTERFACE',
        name: 'InternalUrl',
        possibleTypes: [
          {
            name: 'EntityCanonicalUrl'
          },
          {
            name: 'DefaultInternalUrl'
          }
        ]
      }
    ]
  }
};
