import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { updateRecentSearch } from 'utils/storage';
import { SearchBar } from './SearchBar';
import { AutoComplete } from './AutoComplete';
import { RecentSearches } from './RecentSearches';

export const SearchBarGlobal = () => {
  const history = useHistory();
  const [isActive, setActiveState] = useState(false);
  const [currSearch, updateCurrSearch] = useQueryState('query', '');
  const globalSearch = useRef(null);

  const handleClick = ({ target }) => {
    const isInSearchbar = globalSearch.current.contains(target);
    setActiveState(isInSearchbar);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const showDropown = value => {
    setActiveState(!!value);
  };

  const makeSearch = textContent => {
    history.push({
      pathname: '/search',
      search: `?query=${encodeURIComponent(textContent)}`
    });

    updateCurrSearch(textContent);
    updateRecentSearch(textContent);
    setActiveState(false);
  };

  return (
    <div ref={globalSearch}>
      <div className="searchbar__container">
        <SearchBar
          showDropown={showDropown}
          defaultSearch={currSearch}
          setSearch={makeSearch}
          global
        />
        <div
          className={`searchbar__dropdown ${
            isActive ? 'searchbar__dropdown--active' : ''
          }`}>
          {currSearch.length > 2 && (
            <AutoComplete query={currSearch} makeSearch={makeSearch} />
          )}
          <RecentSearches makeSearch={makeSearch} />
          {/* <div className="searchbar__dropdown__quicklinks">
            <h5>Quick Links</h5>
            <p>Example 1</p>
            <p>Example 2</p>
            <p>Example 3</p>
            <p>Example 4</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
