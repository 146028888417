import React from 'react';

export const Preloader = ({ inline, ...props }) => (
  <span
    className={`preloader-container ${
      inline ? 'preloader-container--inline' : ''
    }`}
    {...props}>
    <span className="preloader">
      <span />
      <span />
    </span>
  </span>
);
