export const fetchAutoComplete = query => {
  return {
    suggest: {
      text: query,
      content: {
        term: {
          field: 'rendered_content',
          size: 10
        }
      }
    }
  };
};
