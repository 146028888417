import React from 'react';
import { useLocation } from 'react-router-dom';

const getTitle = pathname => {
  // there should be a better way
  const pageTitle = pathname.startsWith('/home')
    ? ['Home', 'home']
    : pathname.startsWith('/insights')
    ? ['Insights', 'insights']
    : pathname.startsWith('/regulatory_analysis') ||
      pathname.startsWith('/regulatory-analysis')
    ? ['Regulatory Analysis', 'analysis']
    : pathname.startsWith('/guide')
    ? ['Guides', 'guides']
    : pathname.startsWith('/regulatory_update')
    ? ['Horizon Scanning', 'scanning']
    : pathname.startsWith('/tracker')
    ? ['Trackers', 'trackers']
    : pathname.startsWith('/requirement')
    ? ['Requirements', 'requirement']
    : pathname.startsWith('/rule')
    ? ['Rules', 'rule']
    : pathname.startsWith('/search')
    ? ['Search Results', 'search']
    : pathname.startsWith('/settings/settings')
    ? ['Account Settings', 'settings']
    : pathname.startsWith('/settings/favourites')
    ? ['My Favourites', 'myfavourites']
    : pathname.startsWith('/settings/help')
    ? ['Help', 'help']
    : pathname.startsWith('/settings/profile')
    ? ['My Profile', 'myprofile']
    : ['', ''];

  return pageTitle;
};

const Header = () => {
  const { pathname, state: { title } = {} } = useLocation();
  const [pageTitle, className] = getTitle(pathname);

  return (
    <header className={`compliance__header compliance__header--${className}`}>
      <h1 className="compliance__page-title">{pageTitle || title}</h1>
    </header>
  );
};

export default Header;
