import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

export const Modal = ({
  children,
  className = 'modal',
  closeIcon = '🗙',
  contentLabel = undefined,
  onClose,
  open,
  hideClose = false,
  title = undefined,
  ...props
}) => {
  return (
    <ReactModal
      className={className}
      contentLabel={contentLabel}
      isOpen={open}
      onRequestClose={onClose}
      overlayClassName="Overlay"
      {...props}>
      {!hideClose && (
        <div className="modal-close-btn" onClick={onClose}>
          {closeIcon}
        </div>
      )}
      <div className="modal-header">
        {title && <div className="modal-title">{title}</div>}
      </div>
      <div className="modal-content">{children}</div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  closeIcon: PropTypes.string,
  contentLabel: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  hideClose: PropTypes.bool,
  title: PropTypes.string
};

Modal.defaultProps = {
  className: 'modal',
  closeIcon: '🗙',
  contentLabel: undefined,
  onClose: () => {},
  hideClose: false,
  title: undefined
};
