import React from 'react';

export const InputPassword = ({
  label,
  // isInvalid,
  value,
  onChange,
  onKeyUp,
  onKeyPress,
  className = '',
  placeholder = 'Enter password'
}) => {
  const id = label.replace(/\s/, '-').toLowerCase();
  return (
    <>
      <label htmlFor={id}>
        {/* password temporarily disiabled */}
        {/* {isInvalid && (
          <p className="form__error__message">Enter a valid password</p>
        )} */}
        <input
          id={id}
          title="Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
          type="password"
          className={className}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyPress={onKeyPress}
          required
          autoComplete="off"
          placeholder={placeholder}
        />
      </label>
    </>
  );
};
