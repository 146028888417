import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { updateRecentSearch } from 'utils/storage';
import { clearActiveFilters } from 'api/activeFilters';

export const SearchBar = ({
  global,
  showDropown,
  setSearch,
  defaultSearch
}) => {
  const [currSearch, setCurrSearch] = useState(defaultSearch);
  const node = useRef(null);

  useEffect(() => {
    setCurrSearch(defaultSearch);
  }, [defaultSearch]);

  useEffect(() => {
    node.current.value = currSearch;
  }, [currSearch]);

  const onUpdateSearch = ({ currentTarget: { value } }) => {
    showDropown(value);
    setCurrSearch(value);
  };

  const onSubmitSearch = () => {
    if (currSearch) {
      updateRecentSearch(currSearch);
      showDropown(false);
      clearActiveFilters();
      setSearch(currSearch);
    }
  };

  const onSearchEnter = ({ key, target }) => {
    if (key === 'Enter' && currSearch) {
      target.blur();
      onSubmitSearch();
    }
  };

  const onClearSearch = () => {
    node.current.value = '';
    setCurrSearch('');
  };

  return (
    <>
      <input
        ref={node}
        className="search-results__input"
        type="text"
        name="search"
        onChange={onUpdateSearch}
        value={currSearch}
        onKeyDown={onSearchEnter}
        // onFocus={onClearSearch}
        placeholder="Search"
      />
      {global ? (
        <button
          className="searchbar__submit"
          type="submit"
          disabled={!currSearch}
          onClick={onSubmitSearch}
          id="search"
          aria-label="Search"
        />
      ) : (
        <button
          type="button"
          className="search-results__clear"
          onClick={onClearSearch}
          aria-label="Clear"
        />
      )}
    </>
  );
};

SearchBar.propTypes = {
  global: PropTypes.bool,
  showDropown: PropTypes.func
};

SearchBar.defaultProps = {
  global: false,
  showDropown: () => {}
};
