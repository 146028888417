import React, { Suspense } from 'react';
import { Route, useLocation, Redirect } from 'react-router-dom';
import { setAuthState, useGetAuthStateQuery } from 'api/client/authState';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { fetchUserBookmarks } from '../../api/fetchUserBookmarks';
import { LogIn } from '../LogIn/LogIn';
import { Modal } from '../Modal/Modal';
import { Preloader } from '../common/Preloader';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const client = useApolloClient();
  const closeLogin = () => {
    setAuthState(client, 'loggedOut');
  };
  const {
    data: {
      authState: { state: authState }
    }
  } = useGetAuthStateQuery();
  const { data, error, loading } = useQuery(fetchUserBookmarks, {
    skip: authState !== 'loggedIn'
  });
  if (authState === 'loggedOut')
    return (
      <Redirect
        to={{ pathname: '/loggedout', state: { referrer: location.pathname } }}
      />
    );
  if (authState === 'tokenExpiredError')
    return (
      <Route
        {...rest}
        render={() => (
          <Modal open onClose={closeLogin} contentLabel="Session expired">
            <h3>Your login token has expired! Please login again.</h3>
            <LogIn />
          </Modal>
        )}
      />
    );
  if (error || loading) return null;
  const { currentUserContext } = data;
  const fieldBookmarks =
    currentUserContext && currentUserContext.fieldBookmarks
      ? currentUserContext.fieldBookmarks
      : [];
  const renderRoute = ({ props }) => (
    <Suspense fallback={<Preloader />}>
      <Component {...props} {...rest} bookmarks={fieldBookmarks} />
    </Suspense>
  );
  return (
    <Route {...rest} render={props => renderRoute({ props, Component })} />
  );
};
