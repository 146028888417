import React, { useState } from 'react';
import { handleContactForm } from 'api/authentication';
import {
  updateFormData,
  updateCheckboxData,
  validateText,
  validateEmail
} from 'utils/form';
import { Input } from './Input';
import { Textarea } from './Textarea';

export const Contact = ({ onSuccess, onError }) => {
  const [fields, contactForm] = useState({
    first_name: '',
    last_name: '',
    company: '',
    job_title: '',
    country: '',
    email: '',
    phone: '',
    pc_form_opt_in: 'No',
    enquiry: ''
  });

  const [fieldValidity, setFieldValidity] = useState({
    isFirstNameClean: true,
    isFirstNameValid: false,
    isLastNameClean: true,
    isLastNameValid: false,
    isCompanyClean: true,
    isCompanyValid: false,
    isJobTitleClean: true,
    isJobTitleValid: false,
    isCountryClean: true,
    isCountryValid: false,
    isEmailClean: true,
    isEmailValid: false,
    isPhoneClean: true,
    isPhoneValid: false,
    isEnquiryClean: true,
    isEnquiryValid: false
  });

  const [error, errorHandler] = useState({
    errorMessage: ''
  });

  const { errorMessage } = error;
  const {
    first_name,
    last_name,
    company,
    job_title,
    country,
    email,
    phone,
    pc_form_opt_in,
    enquiry
  } = fields;

  const {
    isFirstNameValid,
    isLastNameValid,
    isCompanyValid,
    isJobTitleValid,
    isCountryValid,
    isEmailValid,
    isPhoneValid,
    isEnquiryValid,
    isFirstNameClean,
    isLastNameClean,
    isCompanyClean,
    isJobTitleClean,
    isCountryClean,
    isEmailClean,
    isPhoneClean,
    isEnquiryClean
  } = fieldValidity;

  const handleError = err => {
    if (err.errorMessage) {
      errorHandler(err);
    } else if (onError) {
      onError(err);
    }
  };

  const handleForm = contactFields => {
    errorHandler({ errorMessage: null });
    return handleContactForm(contactFields)
      .then(data => onSuccess && onSuccess(data))
      .catch(err => handleError(err));
  };

  const getFormValidity = form =>
    Object.entries(form).every(([, value]) => value);

  const submitForm = () => {
    const validKeys = {
      ...{ isFirstNameValid: validateText(first_name) },
      ...{ isLastNameValid: validateText(last_name) },
      ...{ isCompanyValid: validateText(company) },
      ...{ isJobTitleValid: validateText(job_title) },
      ...{ isCountryValid: validateText(country) },
      ...{ isEmailValid: validateEmail(email) },
      ...{ isPhoneValid: validateText(phone) },
      ...{ isEnquiryValid: validateText(enquiry) }
    };

    const cleanKeys = {
      ...{ isFirstNameClean: false },
      ...{ isLastNameClean: false },
      ...{ isCompanyClean: false },
      ...{ isJobTitleClean: false },
      ...{ isCountryClean: false },
      ...{ isEmailClean: false },
      ...{ isPhoneClean: false },
      ...{ isEnquiryClean: false }
    };

    setFieldValidity({
      ...fieldValidity,
      ...validKeys,
      ...cleanKeys
    });

    const isFormValid = getFormValidity(validKeys);

    if (isFormValid) {
      handleForm(fields);
    }
  };

  const marketing =
    'I would like to receive marketing mailings, such as news, events, webinars and promotions from VIXIO PaymentsCompliance';

  return (
    <div className="form contact__form">
      <h1 className="login__title vixio__logo">VIXIO</h1>
      <ul>
        <Input
          name="first name"
          type="text"
          className="form__field"
          isInvalid={!isFirstNameClean && !isFirstNameValid}
          value={first_name}
          onChange={updateFormData(contactForm, 'first_name')}
          required
        />

        <Input
          name="last name"
          type="text"
          className="form__field"
          isInvalid={!isLastNameClean && !isLastNameValid}
          value={last_name}
          onChange={updateFormData(contactForm, 'last_name')}
          required
        />

        <Input
          name="company"
          type="text"
          className="form__field"
          isInvalid={!isCompanyClean && !isCompanyValid}
          value={company}
          onChange={updateFormData(contactForm, 'company')}
          required
        />

        <Input
          name="job title"
          type="text"
          className="form__field"
          isInvalid={!isJobTitleClean && !isJobTitleValid}
          value={job_title}
          onChange={updateFormData(contactForm, 'job_title')}
          required
        />

        <Input
          name="country"
          type="text"
          className="form__field"
          isInvalid={!isCountryClean && !isCountryValid}
          value={country}
          onChange={updateFormData(contactForm, 'country')}
          required
        />

        <Input
          name="email"
          type="email"
          className="form__field"
          isInvalid={!isEmailClean && !isEmailValid}
          value={email}
          onChange={updateFormData(contactForm, 'email')}
          required
          errorMessage="Enter a valid email address"
        />

        <Input
          name="phone number"
          type="tel"
          className="form__field"
          isInvalid={!isPhoneClean && !isPhoneValid}
          value={phone}
          onChange={updateFormData(contactForm, 'phone')}
          required
        />

        <Input
          name="Marketing opt in"
          type="checkbox"
          className="form__field form__checkbox"
          isInvalid={false}
          value={pc_form_opt_in}
          onChange={updateCheckboxData(contactForm, 'pc_form_opt_in')}
          required
          label={marketing}
        />

        <Textarea
          name="enquiry"
          className="form__field"
          isInvalid={!isEnquiryClean && !isEnquiryValid}
          value={enquiry}
          onChange={updateFormData(contactForm, 'enquiry')}
          required
        />

        {errorMessage && <p className="form__error__message">{errorMessage}</p>}

        <button type="button" className="button--primary" onClick={submitForm}>
          Submit
        </button>
      </ul>
    </div>
  );
};
