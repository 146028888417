import gql from 'graphql-tag';
import { client } from './apolloClient';

export const navStatus = gql`
  query GetNavStatus {
    navStatus @client {
      __typename
      status
    }
  }
`;

export const setNavStatus = status => {
  client.writeData({
    data: { navStatus: { __typename: 'nav', status } }
  });
};
