import { fetchQuickFilters } from 'api/fetchQuickFilters';
import { mutateNewFilter } from 'api/mutateNewFilter';
import { client } from 'api/apolloClient';
import { getUserData } from 'api/client/getUserData';
import { getFavouriteTypeFromUrl } from '../utils/utils';

export const createQuickFilter = async (name, filters, emailPreferences) => {
  const {
    location: { pathname, search }
  } = window;
  const {
    currentUserContext: { uid }
  } = client.readQuery({
    query: getUserData
  });

  const input = {
    name,
    filters: filters.map(({ tid }) => tid).join(),
    emailPreferences,
    path: pathname + search,
    type: getFavouriteTypeFromUrl()
  };

  try {
    const {
      data: {
        createQuickFilter: { entity: { entityId = null } = {} } = {}
      } = {}
    } = await client.mutate({
      mutation: mutateNewFilter,
      variables: {
        input
      },
      refetchQueries: [
        {
          query: fetchQuickFilters,
          variables: { userId: uid }
        }
      ]
    });
    return entityId;
  } catch (error) {
    return null;
  }
};
