import React from 'react';
import PropTypes from 'prop-types';
import { fetchElasticAutoComplete } from 'api/elasticRequests/fetchElasticData';
import { fetchAutoComplete } from 'api/elasticRequests/fetchAutoComplete';
import { useQuery } from '@apollo/react-hooks';
import { getEsClient } from '../../utils/utils';

const sortByRelevance = (a, b) => b.relevance - a.relevance;

// temp function until i can write better one
const dedupe = (arr, key) => {
  return arr
    .map(e => e[key])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => arr[e])
    .map(e => arr[e]);
};

const autoComplete = ({ suggest }) => {
  const {
    content: [{ options }]
  } = suggest;

  const results = options
    .map(({ text, score, freq }) => ({ text, relevance: score * freq }))
    .sort(sortByRelevance);

  return dedupe(results, 'text');
};

export const AutoComplete = ({ query, makeSearch }) => {
  const { data, error, loading } = useQuery(fetchElasticAutoComplete, {
    client: getEsClient(),
    variables: { input: fetchAutoComplete(query) }
  });
  if (error) return 'Oops!';
  if (loading) return 'Fetching results...';
  if (!data.elasticData) return null;
  const results = autoComplete(data.elasticData);
  return (
    <ul className="searchbar__autocomplete">
      {results.map(({ text }) => (
        <li
          key={Math.random()}
          className="searchbar__autocomplete__item"
          onClick={makeSearch}>
          {text}
        </li>
      ))}
    </ul>
  );
};

AutoComplete.propTypes = {
  query: PropTypes.string,
  makeSearch: PropTypes.func
};

AutoComplete.defaultProps = {
  query: '',
  makeSearch: () => {}
};
