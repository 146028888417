import { resultSize } from 'utils/constants';

export const fetchGlobalFilter = () => {
  const options = [
    'jurisdictions',
    'authorities',
    'document_types',
    'specialisms',
    'products_and_services',
    'featured_topics',
    'tracker_topics',
    'companies',
    'requirement_categories',
    'requirement_topics',
    'licences'
  ];
  let obj = {};
  options.forEach(option => {
    obj = {
      ...obj,
      ...{
        [option]: {
          terms: {
            field: `name_${option}`,
            ...resultSize,
            order: { _key: 'asc' }
          }
        }
      }
    };
  });

  return {
    aggregations: {
      ...obj
    }
  };
};

export const fetchParentFilter = {
  ...resultSize,
  from: 0,
  query: {
    match_all: {}
  }
};
