import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import Cookie from 'js-cookie';
import { LogInForm } from './LogInForm';
import { ForgottenPasswordForm } from './ForgottenPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';

export const LogIn = ({ isPasswordReset, isForgottenPassword }) => {
  const history = useHistory();
  const [currentForm, formHandler] = useState();
  const client = useApolloClient();
  const showForm = form => () => {
    if (!form) {
      Cookie.remove('access_token');
      Cookie.remove('refresh_token');
      Cookie.remove('client_id');
      client.resetStore().then(() => history.push('/loggedout'));
    }
    formHandler(form);
  };

  useEffect(() => {
    if (isPasswordReset) {
      formHandler('reset');
    }
    if (isForgottenPassword) {
      formHandler('forgotten');
    }
  }, [isPasswordReset, isForgottenPassword]);

  if (currentForm === 'forgotten') {
    return <ForgottenPasswordForm showForm={showForm} />;
  }

  if (currentForm === 'reset') {
    return <ResetPasswordForm showForm={showForm} />;
  }

  return <LogInForm showForm={showForm} />;
};
