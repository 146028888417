import gql from 'graphql-tag';
import { client } from '../apolloClient';

export const getActiveView = gql`
  query CurrentView {
    currentView @client {
      __typename
      name
    }
  }
`;

export const setActiveView = name => {
  client.writeData({
    data: { currentView: { __typename: 'option', name } }
  });
};
