import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const Checkbox = ({ status, tid, vocabulary, updateSelection }) => {
  const handleChange = () => {
    updateSelection(tid);
  };
  const setCheckboxRef = checkbox => {
    if (checkbox) {
      // eslint-disable-next-line no-param-reassign
      checkbox.indeterminate = status === 'indeterminate';
    }
  };
  return (
    <input
      id={`${vocabulary}-${tid}`}
      type="checkbox"
      checked={status === 'checked'}
      ref={setCheckboxRef}
      onChange={handleChange}
    />
  );
};

Checkbox.propTypes = {
  status: PropTypes.string,
  tid: PropTypes.number,
  updateSelection: PropTypes.func
};

Checkbox.defaultProps = {
  status: '',
  tid: 0,
  updateSelection: () => {}
};

export const FilterCheckbox = ({
  tid,
  vocabulary,
  name,
  status,
  childrenTerms,
  updateSelection
}) => {
  const { trackEvent } = useMatomo();
  const [dropdownStatus, toggleDropdown] = useState(false);

  const dropdownSelect = () => {
    toggleDropdown(!dropdownStatus);
    trackEvent({
      category: 'FilterCheckbox',
      action: 'dropdownSelect',
      name,
      value: !dropdownStatus ? 1 : 0
    });
  };

  return (
    <div className={`filtercheckbox__filter ${dropdownStatus ? ' open' : ''}`}>
      <label htmlFor={`${vocabulary}-${tid}`} key={`${vocabulary}-${tid}`}>
        <Checkbox
          status={status}
          key={`${vocabulary}-${tid}`}
          tid={tid}
          vocabulary={vocabulary}
          updateSelection={updateSelection}
        />
        {name}
      </label>
      <span
        className={`filtercheckbox__filter__arrow ${
          childrenTerms.length ? 'visible' : ''
        } ${dropdownStatus ? ' active' : ''}`}
        onClick={dropdownSelect}
      />
      {dropdownStatus &&
        childrenTerms &&
        childrenTerms
          .filter(item => item.countChildren > 0)
          .map(item => {
            return (
              <FilterCheckbox
                key={`${vocabulary}-${item.tid}`}
                tid={item.tid}
                vocabulary={vocabulary}
                name={item.name}
                level={item.level}
                status={item.status}
                childrenTerms={item.children}
                updateSelection={updateSelection}
              />
            );
          })}
    </div>
  );
};

FilterCheckbox.propTypes = {
  tid: PropTypes.number,
  vocabulary: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  childrenTerms: PropTypes.arrayOf(PropTypes.shape(FilterCheckbox.propTypes)),
  updateSelection: PropTypes.func
};

FilterCheckbox.defaultProps = {
  tid: 0,
  vocabulary: '',
  name: '',
  status: '',
  childrenTerms: [],
  updateSelection: () => {}
};
