import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';

const MaintenanceMode = ({ serverError }) => {
  const client = useApolloClient();
  const tryRefresh = () => {
    client.writeData({
      data: { [serverError ? 'isServerError' : 'isMaintenanceMode']: false }
    });
  };
  return (
    <div className="maintenance-mode">
      <div className="maintenance-mode--inner">
        <h1>VIXIO PaymentsCompliance</h1>
        <h2>{serverError ? 'Server error' : 'Site under maintenance'}</h2>
        <p>
          {serverError
            ? 'VIXIO PaymentsCompliance is currently experiencing a server error.'
            : 'VIXIO PaymentsCompliance is currently under maintenance.'}
          <br />
          We should be back shortly. Thank you for your patience.
        </p>
        <button
          type="button"
          onClick={tryRefresh}
          className="button button--primary">
          Try again
        </button>
      </div>
    </div>
  );
};

export default MaintenanceMode;
