import React from 'react';
import { format, fromUnixTime } from 'date-fns';
import { matchPath } from 'react-router-dom';
import { client, elasticClient, elasticClientProxy } from 'api/apolloClient';
import { short, medium, long, filterConfig, routes } from 'utils/constants';
import {
  getCompanyJurisdictions,
  getCompanyProxy
} from 'api/client/permissions';
import { getActiveView } from 'api/client/activeView';

export const stripHTML = str => str && str.replace(/(<([^>]+)>)/gi, '');

export const formatDate = date =>
  date &&
  new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(new Date(date));

export const imageRoot = image => `/images/assets/icons/${image}`;

export const joinTags = (names, paths) => {
  if (names.length === paths.length) {
    return names.map((name, i) => ({
      tagName: name,
      tagPath: paths[i]
    }));
  }
  return [];
};

export const joinParentTags = (names, nodeIds) =>
  names.map((name, i) => ({
    title: name,
    nodeId: nodeIds[i]
  }));

export const truncate = (str, size) => {
  if (str.length < size) {
    return str;
  }

  const firstSpace = str.indexOf(' ', size);
  return str.substr(0, firstSpace).concat(' ...');
};

export const today = separator => {
  const d = new Date();
  const dd = String(d.getDate()).padStart(2, '0');
  const mm = String(d.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = d.getFullYear();

  return `${dd}${separator}${mm}${separator}${yyyy}`;
};

export const dateFormat = (date, label, showTrueDate = false) => {
  let agoStr = 'over a month';
  if (date === undefined) {
    return <></>;
  }
  if (showTrueDate) {
    return (
      <>
        {`${label} `}
        <strong>{format(date, 'dd MMMM yyyy')} </strong>
      </>
    );
  }
  const differenceInTime =
    new Date().getTime() - new Date(date.toJSON().substring(0, 10)).getTime();
  const differenceInDayes = differenceInTime / (1000 * 3600 * 24);
  if (differenceInDayes < 28) {
    agoStr = '3 weeks';
  }
  if (differenceInDayes < 21) {
    agoStr = '2 weeks';
  }
  if (differenceInDayes < 14) {
    agoStr = '1 week';
  }
  if (differenceInDayes < 7) {
    agoStr = '6 days';
  }
  if (differenceInDayes < 6) {
    agoStr = '5 days';
  }
  if (differenceInDayes < 5) {
    agoStr = '4 days';
  }
  if (differenceInDayes < 4) {
    agoStr = '3 days';
  }
  if (differenceInDayes < 3) {
    agoStr = '2 days';
  }
  if (differenceInDayes < 2) {
    agoStr = '1 day';
  }
  if (differenceInDayes < 1) {
    agoStr = 'today';
  }
  return (
    <>
      {`${label} `}
      <strong>
        {agoStr}
        {agoStr !== 'today' ? ' ago' : ''}
      </strong>
    </>
  );
};

export const dateNonUnix = (date, label, showTrueDate = false) => {
  const dateSubstr = date && date.substring(0, 10);
  return dateFormat(new Date(dateSubstr), label, showTrueDate);
};

export const datePublished = (date, showTrueDate = false) => {
  return dateNonUnix(date, 'Date published', showTrueDate);
};

export const datePublishedUnix = (date, showTrueDate = false) => {
  return dateFormat(fromUnixTime(date), 'Date published', showTrueDate);
};

export const dateReleased = (date, showTrueDate = false) => {
  return dateFormat(fromUnixTime(date), 'Release Date', showTrueDate);
};

export const joinAuthorData = (authorIds, authorNames) => {
  return (
    authorIds &&
    authorIds.map((nid, i) => ({
      entity: { title: authorNames[i], nid }
    }))
  );
};

export const convertDate = date =>
  date && format(fromUnixTime(date), 'dd MMMM yyyy');

export const convertNonUnixDate = date =>
  date && format(new Date(date.substring(0, 10)), 'dd MMMM yyyy');

export const getAuthors = authors => {
  if (!authors.length) {
    return '';
  }
  return (
    authors &&
    !!authors.length &&
    authors
      .filter(({ entity }) => entity)
      .map(({ entity: { title } }) => title)
      .join(' and ')
  );
};

export const checkJurisdictions = (ids = []) => {
  const {
    companyJurisdictions: { allJurisdictions, jurisdictions }
  } = client.readQuery({ query: getCompanyJurisdictions });
  // allJurisdictions = allowed to see all regupdates
  if (!allJurisdictions && jurisdictions.length && ids.length) {
    return ids.some(id => jurisdictions.includes(`${id}`));
  }

  return true;
};

export const checkCurrentView = viewType => {
  const {
    currentView: { name }
  } = client.readQuery({ query: getActiveView });

  return name === viewType;
};

// export const checkShowTags = () => {
//   return true;
// };

export const getUnauthorisedText = (length, asHTML) => {
  let text = '';

  switch (length) {
    case 'short':
      text = short;
      break;
    case 'medium':
      text = medium;
      break;
    case 'long':
      text = long;
      break;
    default:
      text = short;
  }

  return asHTML ? `<p>${text}</p>` : text;
};

export const deduplicate = objects => {
  return objects.filter(
    ({ entity }, index, array) =>
      entity &&
      index ===
        array.findIndex(
          item => item.entity && item.entity.entityId === entity.entityId
        )
  );
};

export const hideFilterElements = path => {
  const route = Object.values(routes).find(routeItem =>
    matchPath(path, { path: routeItem })
  );
  if (route && filterConfig[route]) {
    return filterConfig[route];
  }
  return {};
};

export const debounce = (func, wait) => {
  let timeout;
  // eslint-disable-next-line func-names
  return function() {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const extractContent = (hits, contentType) => {
  const content = hits
    .filter(
      ({ fields: { contenttype_subtype: [type] = [] } = {} }) =>
        type === contentType
    )
    .map(
      ({
        inner_hits: {
          by_type: {
            hits: { hits: results }
          }
        }
      }) => results
    );
  if (content && content[0]) {
    return content[0];
  }
  return [];
};

export const isMobile = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

export const createExpires = (relativeDate, maxAge) =>
  new Date(relativeDate.getTime() + maxAge * 1000);

export const WebpIsSupported = async () => {
  // If the browser doesn't has the method createImageBitmap, you can't display
  // webp format
  if (!window.createImageBitmap) {
    return false;
  }

  // Base64 representation of a white point image
  const webpData =
    'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

  // Retrieve the Image in Blob Format
  const blob = await fetch(webpData).then(r => r.blob());

  // If the createImageBitmap method succeeds, return true, otherwise false
  return window.createImageBitmap(blob).then(
    () => true,
    () => false
  );
};

export const getFavouriteTypeFromUrl = () => {
  const {
    location: { pathname }
  } = window;
  const typePaths = {
    all: ['/home'],
    insights: ['/insights'],
    regulatory_analysis: ['/regulatory_analysis'],
    guides: ['/guide'],
    requirements: ['/requirement'],
    horizon_scanning: ['/regulatory_updates/feeds'],
    trackers: ['/tracker-topics'],
    search: ['/search']
  };
  let type = 'all';
  Object.keys(typePaths).forEach(key => {
    if (typePaths[key].find(u => pathname.indexOf(u) === 0)) {
      type = key;
    }
  });
  return type;
};

export const getEsClient = () => {
  const {
    companyProxy: { proxyEs }
  } = client.readQuery({ query: getCompanyProxy });
  if (proxyEs === true) {
    return elasticClientProxy;
  }
  return elasticClient;
};
