import React from 'react';
import PropTypes from 'prop-types';

export const QuickFilters = ({ filterGroups, removeFilter }) => {
  const removeTag = tid => () => {
    removeFilter(tid);
  };

  return (
    <div className="quickfilter__activeFilters">
      {filterGroups.map(group => {
        if (group.length) {
          const groupName = group[0].vid;

          return (
            <ul className="quickfilter__activeFilters__section">
              <li className="quickfilter__activeFilters__vocab">{groupName}</li>
              {group.map(({ tid, name }) => {
                return (
                  <li
                    key={tid}
                    className="quickfilter__activeFilters__tag"
                    onClick={removeTag(tid)}>
                    {name}
                    <span className="quickfilter__activeFilters__tag__remove">
                      🗙
                    </span>
                  </li>
                );
              })}
            </ul>
          );
        }
        return null;
      })}
    </div>
  );
};

QuickFilters.propTypes = {
  removeFilter: PropTypes.func
};

QuickFilters.defaultProps = {
  removeFilter: () => {}
};
