import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getActiveFilters } from 'api/activeFilters';
import { createQuickFilter } from 'api/createQuickFilter';
import { QuickFilters } from 'components/QuickFilters/QuickFilters';

export const QuickFilterForm = ({ onSuccess, onError }) => {
  const [selectedFilters, updateLocalFilters] = useState([]);
  const [quickFilterName, updateName] = useState('');
  const [emailPreferences, updateEmailPreferences] = useState('none');

  useEffect(() => {
    updateLocalFilters(getActiveFilters());
  }, []);

  const removeLocalFilter = id => {
    const updatedFilter = selectedFilters.filter(({ tid }) => tid !== id);
    updateLocalFilters(updatedFilter);
  };

  const saveFilters = () => {
    createQuickFilter(quickFilterName, selectedFilters, emailPreferences).then(
      id => {
        if (id) {
          onSuccess('Successfully saved favourite.');
        } else {
          onError('An error has occurred. Please try again later');
        }
      }
    );
  };

  const updateFilterName = ({ currentTarget: { value } }) => {
    updateName(value);
  };

  const changeEmailPreferences = ({ currentTarget: { value } }) => {
    updateEmailPreferences(value);
  };

  const handleEnterKey = e => {
    if (e.key === 'Enter' && quickFilterName) {
      saveFilters();
    }
  };

  const sortFilters = quickFilters => {
    return [
      quickFilters.filter(({ vid }) => vid === 'jurisdictions'),
      quickFilters.filter(({ vid }) => vid === 'authorities'),
      quickFilters.filter(({ vid }) => vid === 'document_types'),
      quickFilters.filter(({ vid }) => vid === 'specialisms'),
      quickFilters.filter(({ vid }) => vid === 'products_and_services'),
      quickFilters.filter(({ vid }) => vid === 'featured_topics'),
      quickFilters.filter(({ vid }) => vid === 'tracker_topics'),
      quickFilters.filter(({ vid }) => vid === 'requirement_categories'),
      quickFilters.filter(({ vid }) => vid === 'licences')
    ];
    // better way?
    // return quickFilters.map(filter => {
    //   // return [
    //   //   ...jurisdictions,
    //   //   ...(filter.vid === 'jurisdictions' ? [filter] : [])
    //   // ];
    // });
  };

  const filterGroups = sortFilters(selectedFilters);

  return (
    <div className="quickfilter">
      <label htmlFor="filtername">
        <p>Saved Name:</p>
        <input
          className="form__field"
          type="text"
          id="filtername"
          name="filtername"
          maxLength="50"
          onChange={updateFilterName}
          onKeyDown={handleEnterKey}
        />
      </label>
      <div>
        <p>Selected Filters</p>
        <QuickFilters
          filterGroups={filterGroups}
          removeFilter={removeLocalFilter}
        />
      </div>

      <div>
        <p>Alert service email preferences</p>
        <select value={emailPreferences} onChange={changeEmailPreferences}>
          <option value="none">No alerts</option>
          <option value="daily">Daily alerts</option>
          <option value="weekly">Weekly alerts</option>
          <option value="monthly">Monthly alerts</option>
        </select>
      </div>

      <button
        type="button"
        className="button button--primary"
        onClick={saveFilters}
        disabled={!selectedFilters.length || !quickFilterName}>
        Save
      </button>
    </div>
  );
};

QuickFilterForm.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

QuickFilterForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {}
};
