import gql from 'graphql-tag';
import { client } from 'api/apolloClient';
import { useQuery } from '@apollo/react-hooks';

export const getCompanyJurisdictions = gql`
  query GetCompanyJurisdictions {
    companyJurisdictions @client {
      __typename
      allJurisdictions
      jurisdictions
    }
  }
`;

export const setCompanyJurisdictions = (
  allJurisdictions = false,
  jurisdictions = []
) => {
  client.writeData({
    data: {
      companyJurisdictions: {
        __typename: 'jurisdictions',
        allJurisdictions,
        jurisdictions: [...jurisdictions]
      }
    }
  });
};

export const getCompanyProxy = gql`
  query GetCompanyProxy {
    companyProxy @client {
      __typename
      proxyEs
    }
  }
`;

export const setCompanyProxy = (proxyEs = false) => {
  client.writeData({
    data: {
      companyProxy: {
        __typename: 'proxy',
        proxyEs
      }
    }
  });
};

export const permissionsList = {
  viewArticle: 'viewArticle',
  pdfArticle: 'pdfArticle',
  viewGuide: 'viewGuide',
  pdfGuide: 'pdfGuide',
  viewRequirement: 'viewRequirement',
  pdfRequirement: 'pdfRequirement',
  viewHorizonScanning: 'viewHorizonScanning',
  pdfHorizonScanning: 'pdfHorizonScanning',
  viewTracker: 'viewTracker',
  pdfTracker: 'pdfTracker',
  viewRule: 'viewRule'
};

export const permissionsListDesc = {
  viewArticle:
    'View articles (Insight & Regulatory Analysis)  in list and full',
  pdfArticle:
    'Download PDF-version of articles (Insight & Regulatory Analysis)',
  viewGuide: 'View Guide in list and full',
  pdfGuide: 'Download PDF-version of Guide',
  viewRequirement: 'View Requirement in list and full',
  pdfRequirement: 'Download PDF-version of Requirement',
  viewHorizonScanning:
    'View Horizon Scanning in list and full based on Company jurisdictions',
  pdfHorizonScanning:
    'Download PDF-version of Horizon Scanning based on Company jurisdictions',
  viewTracker: 'View Tracker content in list and full',
  pdfTracker: 'Download PDF-version of Tracker content',
  viewRule: 'View Rule content in list and full'
};

export const getAccess = gql`
  query GetUserPermissions {
    userAccess @client {
      __typename
      access
    }
  }
`;

export const getProductAccess = (
  salesforceId = '',
  trial = true,
  jurisdictions = []
) => {
  const access = [];

  // Guides
  if (salesforceId === '01t3X00000FyePIQAZ') {
    access.push(permissionsList.viewGuide);
    if (trial !== true) {
      access.push(permissionsList.pdfGuide);
    }
  }
  // Articles
  if (salesforceId === '01t3X00000FyswEQAR') {
    access.push(permissionsList.viewArticle);
    if (trial !== true) {
      access.push(permissionsList.pdfArticle);
    }
  }
  // Horizon Scanning
  const hsList = [
    '01t3X00000GZAMzQAP',
    '01t3X00000GZAN4QAP',
    '01t3X00000FydxsQAB',
    '01t3X00000GZAFZQA5',
    '01t3X00000Fydy2QAB'
  ];
  if (hsList.includes(salesforceId)) {
    access.push(permissionsList.viewHorizonScanning);
    jurisdictions.forEach(j => {
      access.push(`${permissionsList.viewHorizonScanning}_${j}`);
    });
    if (trial !== true) {
      access.push(permissionsList.pdfHorizonScanning);
      jurisdictions.forEach(j => {
        access.push(`${permissionsList.pdfHorizonScanning}_${j}`);
      });
    }
  }
  // Trackers
  if (salesforceId === '01t3X00000FyePNQAZ') {
    access.push(permissionsList.viewTracker);
    if (trial !== true) {
      access.push(permissionsList.pdfTracker);
    }
  }
  // VIXIO PC Insights, Trackers, Guides, Requirements
  if (
    salesforceId === '01t3X00000GZIsJQAX' ||
    salesforceId === '01t3X00000Fydy2QAB'
  ) {
    access.push(permissionsList.viewArticle);
    access.push(permissionsList.viewGuide);
    access.push(permissionsList.viewRequirement);
    access.push(permissionsList.viewRule);
    access.push(permissionsList.viewTracker);
    if (trial !== true) {
      access.push(permissionsList.pdfArticle);
      access.push(permissionsList.pdfGuide);
      access.push(permissionsList.pdfRequirement);
      access.push(permissionsList.pdfTracker);
    }
  }

  return access;
};

const extractJurisdictions = (company = undefined) => {
  if (company === undefined) {
    return [];
  }
  if (company.fieldAllJurisdictions === true) {
    return [0];
  }
  if (company.fieldJurisdictions === undefined) {
    return [];
  }
  return company.fieldJurisdictions.map(j => j.entity.entityId);
};

export const setAccess = (subscriptions = [], company = {}) => {
  let access = [];
  const jurisdictions = extractJurisdictions(company);
  subscriptions.forEach(s => {
    if (
      s.fieldActive === true &&
      s.fieldProduct.entity.fieldSalesforceId !== undefined
    ) {
      // TODO: Check Exipre Date
      access = [
        ...access,
        ...getProductAccess(
          s.fieldProduct.entity.fieldSalesforceId,
          s.fieldTrial,
          jurisdictions
        )
      ];
    }
  });
  client.writeData({
    data: {
      userAccess: {
        __typename: 'accesses',
        access
      }
    }
  });
};

export const useGetAccessList = () => {
  const {
    data: {
      userAccess: { access }
    }
  } = useQuery(getAccess);
  return access;
};

export const checkAccess = (accessList, permission, ids = []) => {
  // If this array object is empty it simply may not have loaded yet
  if (!accessList.length) {
    return true;
  }

  if (!ids.length) {
    return accessList.includes(permission);
  }
  ids.unshift(0);
  return ids.reduce((a, c) => {
    return a || accessList.includes(`${permission}_${c}`);
  }, false);
};

export const useCheckAccess = (permission, ids = []) => {
  return checkAccess(useGetAccessList(), permission, ids);
};

export const checkAccessRegUpdateView = (accessList, regupdate) => {
  let access = false;
  let hsTids = [];
  // GraphlqL object
  if (regupdate.fieldHorizonScanning) {
    hsTids = regupdate.fieldJurisdictions.map(e => e.entity.entityId);
  }
  // ES object
  if (regupdate.field_horizon_scanning && regupdate.field_horizon_scanning[0]) {
    hsTids = regupdate.field_jurisdictions;
  }
  access =
    access ||
    checkAccess(accessList, permissionsList.viewHorizonScanning, hsTids);
  access =
    access ||
    ((regupdate.fieldTracker ||
      (regupdate.field_tracker && regupdate.field_tracker[0])) &&
      checkAccess(accessList, permissionsList.viewTracker));
  return access;
};
export const checkAccessRegUpdatePDF = (accessList, regupdate) => {
  let access = false;
  let hsTids = [];
  if (regupdate.fieldHorizonScanning) {
    hsTids = regupdate.fieldJurisdictions.map(e => e.entity.entityId);
  }
  access =
    access ||
    checkAccess(accessList, permissionsList.pdfHorizonScanning, hsTids);
  access =
    access ||
    (checkAccess(accessList, permissionsList.pdfTracker) &&
      regupdate.fieldTracker);
  return access;
};

export const useGetAccessArray = () => {
  const accessList = useGetAccessList();
  const hasArticlesPermissions = checkAccess(
    accessList,
    permissionsList.viewArticle
  );
  const hasRegUpdatesPermissions = checkAccess(
    accessList,
    permissionsList.viewHorizonScanning
  );
  const hasGuidesPermissions = checkAccess(
    accessList,
    permissionsList.viewGuide
  );
  const hasTrackerPermissions = checkAccess(
    accessList,
    permissionsList.viewTracker
  );

  return {
    accessList,
    hasArticlesPermissions,
    hasRegUpdatesPermissions,
    hasGuidesPermissions,
    hasTrackerPermissions
  };
};
