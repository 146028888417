import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { client } from './api/apolloClient';
import { App } from './components/App/App';
import './index.scss';
import MaintenanceMode from './components/MaintenanceMode/MaintenanceMode';

const IS_SERVER_ERROR = gql`
  query IsServerError {
    isServerError @client
  }
`;

let toastId;
let toastShown;
// eslint-disable-next-line
const IsServerError = () => {
  const { data } = useQuery(IS_SERVER_ERROR);
  if (data.isServerError && !toastId) {
    toastId = toast.error(
      'We are experiencing a temporary server outage. You may continue browsing the site but some pages might fail to load.',
      { autoClose: false }
    );
    toastShown = true;
  } else if (toastId) {
    toast.dismiss(toastId);
    toastId = undefined;
  } else if (!data.isServerError && toastShown) {
    if (toastId) {
      toast.dismiss(toastId);
    }
    toastId = undefined;
    toast.info('Server issues have been resolved.');
    toastShown = undefined;
  }
  return null;
};

const IS_MAINTENANCE_MODE = gql`
  query IsMaintenanceMode {
    isMaintenanceMode @client
  }
`;

const IsMaintenaceMode = () => {
  const { data } = useQuery(IS_MAINTENANCE_MODE);
  const { data: serverErrorData } = useQuery(IS_SERVER_ERROR);
  return data.isMaintenanceMode ? (
    <MaintenanceMode />
  ) : serverErrorData.isServerError ? (
    <MaintenanceMode serverError />
  ) : (
    <App />
  );
};

// @TODO: Server error as message while allowing browsing of cached data
ReactDOM.render(
  <ApolloProvider client={client}>
    <IsMaintenaceMode />
    {/* <IsServerError /> */}
  </ApolloProvider>,
  document.getElementById('root')
);
