import gql from 'graphql-tag';
import { client } from './apolloClient';

export const checkedTerms = gql`
  query GlobalFilters {
    globalFilters @client {
      name
      tid
      vid
      __typename
      active
    }
    currentView @client {
      name
    }
  }
`;

const reduceFilters = array => {
  client.writeData({ data: { globalFilters: [...array] } });
};

export const getActiveFilters = () => {
  const activeFilters = client.readQuery({ query: checkedTerms });
  const globalFilters = activeFilters && activeFilters.globalFilters;

  return globalFilters;
};

export const clearActiveFilters = () => {
  reduceFilters([]);
};

export const removeActiveFilter = filterTid => {
  const globalFilters = getActiveFilters();

  if (globalFilters.length) {
    const existing = globalFilters.map(({ tid }) => tid).indexOf(filterTid);

    if (existing >= 0) {
      const newFilters = globalFilters.filter(({ tid }) => tid !== filterTid);
      reduceFilters([...newFilters]);
    }
  }
};

export const updateActiveFilters = ({ name, vid, tid: id }, active) => {
  const filter = { name, vid, tid: id, __typename: 'filter', active };
  const globalFilters = getActiveFilters();

  if (globalFilters.length) {
    const existing = globalFilters.map(({ tid }) => tid).indexOf(filter.tid);

    if (existing >= 0) {
      const newFilters = globalFilters.filter(
        ({ tid: filterTid }) => filterTid !== filter.tid
      );

      reduceFilters([...newFilters]);
    } else {
      reduceFilters([...globalFilters, filter]);
    }
  } else {
    reduceFilters([filter]);
  }
};

export const overwriteActiveFilters = filters => {
  const allFilters = filters.map(({ name, vid, tid }) => ({
    name,
    vid,
    tid,
    __typename: 'filter',
    active: true
  }));
  reduceFilters(allFilters);
};
