import gql from 'graphql-tag';

export const getUserData = gql`
  query GetUserData {
    currentUserContext {
      uid
      fieldFirstName
      fieldLastName
      fieldJobTitle
      fieldDailyEmail
      fieldWeeklyEmail
      fieldMonthlyEmail
      mail
      entityCreated
    }
  }
`;
