import gql from 'graphql-tag';

export const fetchUserDetails = gql`
  query FetchUserDetails {
    currentUserContext {
      uid
      fieldFirstName
      fieldLastName
      fieldJobTitle
      fieldDailyEmail
      fieldWeeklyEmail
      fieldMonthlyEmail
      mail
      entityCreated
      fieldSalesforceId
      fieldCompany {
        entity {
          entityId
          entityLabel
          fieldSalesforceId
          fieldAllJurisdictions
          fieldJurisdictions {
            entity {
              entityId
              entityLabel
            }
          }
          fieldProxyEs
        }
      }
      reverseFieldUserSubscription(limit: 100) {
        entities {
          entityId
          ...Subscription
        }
      }
    }
  }

  fragment Subscription on Subscription {
    id
    name
    fieldActive
    fieldUser {
      targetId
    }
    fieldTrial
    fieldExpiryDate {
      value
    }
    fieldProduct {
      entity {
        ...Product
      }
    }
  }

  fragment Product on SalesforceProduct {
    id
    entityLabel
    fieldActive
    fieldSalesforceId
  }
`;
