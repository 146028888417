import React from 'react';

export default () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Sorry, page was not found</h2>
    </div>
  );
};
