import React, { useState } from 'react';
import Cookie from 'js-cookie';
import { handleOauth, handleResponse } from 'api/authentication';
import { oAuthClientId } from 'utils/constants';
import { updateFormData, validateEmail, validatePassword } from 'utils/form';
import { createExpires } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { setAuthState } from 'api/client/authState';
import { useApolloClient } from '@apollo/react-hooks';
import { InputPassword as Password } from '../Form/InputPassword';
import { InputEmail as Email } from '../Form/InputEmail';

const counter = 0;

export const LogInForm = ({ showForm }) => {
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const [user, logIn] = useState({
    grant_type: 'password',
    client_id: oAuthClientId,
    username: Cookie.get('username') || '',
    password: ''
  });

  const [error, errorHandler] = useState({
    errorMessage: '',
    errorCount: counter
  });

  const [fieldValidity, setFieldValidity] = useState({
    isEmailClean: !user.username,
    isPasswordClean: true,
    isEmailValid: user.username ? validateEmail(user.username) : false,
    isPasswordValid: false
  });

  const handleError = status => {
    if (status === 503) {
      errorHandler({
        errorMessage:
          'Service currently unavailable. Please check back in a few minutes!'
      });
    }
    if (status === 401) {
      const totalLoginAttempts = counter + 1;

      errorHandler({
        errorMessage:
          totalLoginAttempts < 5
            ? 'Invalid username or password.'
            : 'You have exceeded permitted log in attempts',
        errorCount: totalLoginAttempts
      });
    }

    if (status > 401 && status < 500) {
      errorHandler({ errorMessage: 'An error has occurred.' });
    }
  };

  const finalizeLogin = json => {
    const requestDate = new Date();
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expires
    } = json;
    Cookie.set('access_token', accessToken, {
      expires: createExpires(requestDate, expires),
      path: '/'
    });
    Cookie.set('refresh_token', refreshToken, {
      expires: createExpires(requestDate, 1209600),
      path: '/'
    });
    Cookie.set('client_id', user.client_id, {
      expires: createExpires(requestDate, 1209600),
      path: '/'
    });
    Cookie.set('username', user.username, {
      expires: createExpires(requestDate, 1209600),
      path: '/'
    });
    setAuthState(client, 'loggedIn');

    const pardotLoginUrl = `https://go.vixio.com/l/77152/2020-09-07/9nwh17?email=${user.username}`;
    fetch(pardotLoginUrl);
  };

  const handleLogin = async userDetails => {
    try {
      const data = await handleOauth(userDetails);
      const json = await handleResponse(data);
      finalizeLogin(json);
      if (location.state.referrer) {
        history.replace(location.state.referrer);
      } else {
        history.replace('/home');
      }
    } catch (loginError) {
      handleError(loginError.status);
    }
  };

  const submitLogin = ({ key }) => {
    if (key && key !== 'Enter') {
      return;
    }

    const isEmailValid = validateEmail(user.username);
    // eslint-disable-next-line no-use-before-define
    const isPasswordValid = isPasswordClean
      ? false
      : validatePassword(user.password);

    setFieldValidity({
      ...fieldValidity,
      ...{ isEmailValid },
      ...{ isPasswordValid },
      ...{ isEmailClean: false },
      ...{ isPasswordClean: false }
    });

    // if (isEmailValid && isPasswordValid) {
    // password temporarily disabled
    if (isEmailValid) {
      handleLogin(user);
    }
  };

  const { errorMessage } = error;
  const { username, password } = user;
  const {
    isEmailClean,
    isPasswordClean,
    isEmailValid,
    isPasswordValid
  } = fieldValidity;

  return (
    <div className="login__form">
      <h1 className="login__title vixio__logo">Vixio</h1>

      {errorMessage && <p className="form__error__message">{errorMessage}</p>}
      <form>
        <Email
          label="Username"
          className="form__field login__form__field"
          isInvalid={!isEmailClean && !isEmailValid}
          value={username}
          onChange={updateFormData(logIn, 'username')}
          onKeyPress={submitLogin}
        />

        <Password
          label="Password"
          className="form__field login__form__field"
          isInvalid={!isPasswordClean && !isPasswordValid}
          value={password}
          onChange={updateFormData(logIn, 'password')}
          onKeyPress={submitLogin}
        />

        <button
          type="button"
          className="button button--primary"
          onClick={submitLogin}>
          Log In
        </button>
      </form>

      <p className="login__form__link" onClick={showForm('forgotten')}>
        Request password reset
      </p>
    </div>
  );
};
