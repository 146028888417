import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import { handleOauth } from 'api/authentication';
import {
  graphqlApi,
  restApi,
  restApiAuth,
  restApiAuthKey,
  restApiProxy
} from 'utils/constants';
import Cookie from 'js-cookie';
import { isMobile, createExpires } from 'utils/utils';
import gql from 'graphql-tag';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { resolvers, typeDefs } from './apolloResolvers';
import introspectionQueryResultData from '../introspection-result';
import { getAuthStateQuery, setAuthState } from './client/authState';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const throwServerError = (response, result, message) => {
  const error = new Error(message);
  error.name = 'ServerError';
  error.response = response;
  error.statusCode = response.status;
  error.result = result;
  throw error;
};

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'token',
  isTokenValidOrUndefined: () => {
    const accessToken = Cookie.get('access_token');
    const refreshToken = Cookie.get('refresh_token');
    const clientId = Cookie.get('client_id');
    return (
      accessToken !== undefined ||
      refreshToken === undefined ||
      clientId === undefined
    );
  },
  fetchAccessToken: () => {
    const refreshToken = Cookie.get('refresh_token');
    const clientId = Cookie.get('client_id');
    const details = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: clientId
    };
    return handleOauth(details);
  },
  handleFetch: ({ access_token, refresh_token }) => {
    const requestDate = new Date();
    Cookie.set('access_token', access_token, {
      expires: createExpires(requestDate, 300),
      path: '/'
    });
    Cookie.set('refresh_token', refresh_token, {
      expires: createExpires(requestDate, 1209600),
      path: '/'
    });
    // eslint-disable-next-line no-use-before-define
    setAuthState(client, 'loggedIn');
  },
  handleError: err => {
    // eslint-disable-next-line no-console
    console.error(err);
    Cookie.remove('access_token');
    Cookie.remove('refresh_token');
    Cookie.remove('client_id');
    // eslint-disable-next-line no-use-before-define
    cache.writeData({
      data: {
        authState: { __typename: 'authStatus', state: 'tokenExpiredError' }
      }
    });
  },

  handleResponse: operation => response =>
    response
      .text()
      .then(bodyText => {
        try {
          return JSON.parse(bodyText);
        } catch (err) {
          const parseError = err;
          parseError.response = response;
          parseError.statusCode = response.status;
          parseError.bodyText = bodyText;
          return Promise.reject(parseError);
        }
      })
      .then(parsedBody => {
        if (response.status >= 300) {
          throwServerError(
            response,
            parsedBody,
            `Response not successful: Received status code ${response.status}`
          );
        }
        if (
          !Object.prototype.hasOwnProperty.call(parsedBody, 'access_token') &&
          parsedBody.data &&
          !Object.prototype.hasOwnProperty.call(parsedBody, 'access_token') &&
          !Object.prototype.hasOwnProperty.call(parsedBody, 'errors')
        ) {
          throwServerError(
            response,
            parsedBody,
            `Server response was missing for query '${operation.operationName}'`
          );
        }
        return { token: parsedBody };
      })
});

const withToken = setContext(() => {
  const token = Cookie.get('access_token');
  return {
    headers: {
      authorization: token && `Bearer ${token}`
    }
  };
});

const cache = new InMemoryCache({
  fragmentMatcher
});

const IS_SERVER_ERROR = gql`
  query IsServerError {
    isServerError @client
  }
`;

const httpLink = new HttpLink({ uri: graphqlApi });
const resetServerError = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (cache.readQuery({ query: IS_SERVER_ERROR }).isServerError) {
      // eslint-disable-next-line no-use-before-define
      client.writeData({ data: { isServerError: false } });
    }
    return response;
  })
);
const resetServerErrorLink = resetServerError.concat(httpLink);

const client = new ApolloClient({
  link: ApolloLink.from([
    refreshLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) {
        if (networkError.statusCode === 401) {
          const {
            authState: { state }
          } = client.readQuery({ query: getAuthStateQuery });
          if (state === 'loggedIn') {
            cache.writeData({
              data: {
                authState: {
                  __typename: 'authStatus',
                  state: 'tokenExpiredError'
                }
              }
            });
          }
        }
        if (networkError.statusCode === 503) {
          cache.writeData({ data: { isMaintenanceMode: true } });
        } else if (networkError.statusCode === 500) {
          client.writeData({ data: { isServerError: true } });
        } else {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`);
        }
      }
    }),
    withToken,
    resetServerErrorLink
  ]),
  cache,
  typeDefs,
  resolvers
});

const writeDefaults = () => {
  const accessToken = Cookie.get('access_token');
  const refreshToken = Cookie.get('refresh_token');
  cache.writeData({
    data: {
      initials: {
        __typename: 'Initials',
        value: 'Ω'
      },
      authState: {
        __typename: 'authStatus',
        state: accessToken || refreshToken ? 'loggedIn' : 'loggedOut'
      },
      isMaintenanceMode: false,
      isShowAllTagsMode: localStorage.getItem('taggedStatus') === 'true',
      isServerError: false,
      globalFilters: [],
      enabledFilters: [],
      activeCountry: {
        country: '',
        __typename: 'country'
      },
      navStatus: { __typename: 'nav', status: !isMobile() },
      currentView: { __typename: 'option', name: 'list' },
      collectionId: { __typename: 'collectionId', collectionId: '' },
      userPermissions: {
        __typename: 'permissions',
        permissions: []
      },
      trialPermissions: {
        __typename: 'permissions',
        permissions: {
          __typename: 'permission'
        }
      },
      companyJurisdictions: {
        __typename: 'jurisdictions',
        allJurisdictions: false,
        jurisdictions: []
      },
      companyProxy: {
        __typename: 'proxy',
        proxyEs: false
      },
      userAccess: {
        __typename: 'accesses',
        access: []
      }
    }
  });
};
client.onResetStore(writeDefaults);
writeDefaults();

// ECS Apollo Client
const restLink = new RestLink({
  uri: restApi,
  credentials: 'omit',
  headers: {
    authorization: restApiAuth
  }
});

// ECS Apollo Client Proxy
const restLinkProxy = new RestLink({
  uri: restApiProxy,
  credentials: 'omit',
  headers: {
    'X-Auth-Token': restApiAuthKey
  }
});

// Note that the ECS Apollo client 'elasticClient' requires a value for cache,
// but app uses the GQL cache in 'client' as its local cache for storing app
// Global State

const elasticClient = new ApolloClient({
  link: ApolloLink.from([
    onError(error => {
      // eslint-disable-next-line no-console
      console.log('ECS Error', error);
    }),
    restLink
  ]),
  cache: new InMemoryCache()
});

const elasticClientProxy = new ApolloClient({
  link: ApolloLink.from([
    onError(error => {
      // eslint-disable-next-line no-console
      console.log('ECS Proxy Error', error);
    }),
    restLinkProxy
  ]),
  cache: new InMemoryCache()
});

export { client, elasticClient, elasticClientProxy };
