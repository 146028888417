import { Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Preloader } from '../common/Preloader';

export const LazyRoute = ({ component: Component, ...rest }) => {
  const renderRoute = ({ props }) => (
    <Suspense fallback={<Preloader />}>
      <Component {...props} {...rest} />
    </Suspense>
  );
  return (
    <Route {...rest} render={props => renderRoute({ props, Component })} />
  );
};
