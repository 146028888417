import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchQuickFilters } from 'api/fetchQuickFilters';
import { getUserData } from 'api/client/getUserData';
import { overwriteActiveFilters } from 'api/activeFilters';
import { QuickFilter } from 'components/Nav/QuickFilter';
import { Preloader } from 'components/common/Preloader';
import { useQuery } from '@apollo/react-hooks';
import { getFavouriteTypeFromUrl } from '../../utils/utils';

export const QuickFilterSection = () => {
  let userId;
  const { state: { filters } = {} } = useLocation();
  const { location: { pathname } = {} } = window;
  const { data: userData, error: userError, loading: userLoading } = useQuery(
    getUserData
  );
  if (userData) {
    userId = userData.currentUserContext.uid;
  }
  const {
    data: { quickFilterQuery: { entities } = {} } = {},
    error,
    loading
  } = useQuery(fetchQuickFilters, { variables: { userId }, skip: !userId });
  useEffect(() => {
    if (filters) {
      const formattedFilters = filters.map(
        ({
          targetId: tid,
          entity: {
            name,
            vid: { targetId: vid }
          }
        }) => ({ name, vid, tid })
      );
      overwriteActiveFilters(formattedFilters);
    }
  }, [filters]);
  if (error || userError) {
    return 'Oops!';
  }
  if (loading || userLoading) {
    return <Preloader inline />;
  }
  const currentPageType = getFavouriteTypeFromUrl();
  return (
    <div className="quick-filters-nav">
      <div className="quick-filters-nav__header">
        <span className="quick-filters-nav__header__star">&#9734;</span>
        <span className="quick-filters-nav__header__text">Favourites</span>
        <Link to="/settings/favourites">Manage</Link>
      </div>
      {entities &&
        entities[0] &&
        entities.map(
          ({ id, name, fieldFilters, fieldType, fieldFePath = {} }) => {
            if (currentPageType === fieldType || pathname === fieldFePath) {
              return (
                <QuickFilter key={id} name={name} fieldFilters={fieldFilters} />
              );
            }
            return null;
          }
        )}
    </div>
  );
};
