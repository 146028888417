import gql from 'graphql-tag';

export const fetchUserBookmarks = gql`
  query FetchUserBookmarks {
    currentUserContext {
      entityId
      fieldBookmarks {
        targetId
        entity {
          entityLabel
          entityUrl {
            routed
            path
          }
        }
      }
    }
  }
`;
