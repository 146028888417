/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

export const Textarea = ({
  isInvalid,
  value,
  onChange,
  className = '',
  name,
  errorMessage
}) => {
  const message = !errorMessage ? `Please enter your ${name}` : errorMessage;

  return (
    <li>
      {isInvalid && <p className="form__error__message">{message}</p>}
      <label htmlFor={name}>{name}</label>
      <textarea
        id={name}
        className={className}
        value={value}
        onChange={onChange}
        required
        autoComplete="off"
        placeholder={`Enter ${name}`}
      />
    </li>
  );
};
