import 'react-toggle/style.css';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { checkedTerms, clearActiveFilters } from 'api/activeFilters';
import { client } from 'api/apolloClient';
import { Modal } from 'components/Modal/Modal';
import { QuickFilterForm } from 'components/QuickFilters/QuickFilterForm';
import { hideFilterElements } from 'utils/utils';
import { getTaggedStatus, updateTaggedStatus } from 'utils/storage';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import Toggle from 'react-toggle';
import { ViewIcon } from './ViewIcon';
import { Filter } from './Filter';

export const GlobalFilterBar = () => {
  const location = useLocation();
  const [barStatus, toggleBar] = useState(false);
  const [show, showModal] = useState(false);
  const { data: { globalFilters } = [] } = useQuery(checkedTerms);

  const expandBar = () => {
    toggleBar(!barStatus);
  };

  const toggleModal = status => () => {
    showModal(status);
  };

  const handleSuccess = text => {
    toast.success(text);
    showModal(false);
  };

  const handleError = text => {
    toast.error(text);
    showModal(false);
  };

  // const systemPrefersShowAllTags = localStorage.getItem('showAllTags') ===
  // "true" ? true : false;
  const systemPrefersShowAllTags = getTaggedStatus();
  // const systemPrefersShowAllTags = false;

  const [allTags, setAllTagsShown] = useState(systemPrefersShowAllTags);

  useEffect(() => {
    if (!allTags) {
      client.writeData({ data: { isShowAllTagsMode: false } });
      updateTaggedStatus(false);
    } else {
      client.writeData({ data: { isShowAllTagsMode: true } });
      updateTaggedStatus(true);
    }
  });

  const expanded = barStatus ? 'expanded' : '';
  const text = barStatus ? 'Minimise' : 'Expand';
  const hasFilters = globalFilters.length;
  const { hideFilters, hideViewSelector } = hideFilterElements(
    location.pathname
  );

  if (hideFilters) {
    return null;
  }

  return (
    <div className={`filterbar ${expanded}`}>
      {!!hasFilters && <p className="filterbar__filter-text">Filter:</p>}
      <div
        className={`filterbar__selected-filters ${
          hideViewSelector ? 'extended' : ''
        }`}>
        {Object.values(globalFilters).map(({ name, tid, active }) => (
          <Filter key={tid} name={name} tid={tid} active={active} />
        ))}
      </div>
      {!!hasFilters && (
        <p
          className="filterbar__expand"
          onClick={expandBar}
          onKeyPress={expandBar}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}>
          {text}
        </p>
      )}

      <div className="filterbar__button">
        <button
          type="button"
          className="filterbar__button__clear"
          disabled={!hasFilters}
          onClick={clearActiveFilters}>
          Clear
        </button>
        <button
          type="button"
          className="filterbar__button__star"
          disabled={!hasFilters}
          onClick={toggleModal(!show)}>
          {hasFilters ? (
            <span className="filterbar__button__star__icon filterbar__button__star__icon--active">
              &#9733;
            </span>
          ) : (
            <span className="filterbar__button__star__icon">&#9734;</span>
          )}
        </button>
      </div>
      <div className={`filterbar__view ${hideViewSelector ? 'hidden' : ''}`}>
        <p className="filterbar__view__text">View:</p>
        <ViewIcon type="grid" />
        <ViewIcon type="list" />
        <ViewIcon type="inbox" />
      </div>
      <div
        className={`filterbar__tagtoggle ${hideViewSelector ? 'hidden' : ''}`}>
        <span className="filterbar__tagtoggle__text">Show all tags</span>
        <div className="filterbar__tagtoggle__wrapper">
          <Toggle
            className="TagsToggle"
            onChange={showAllTags =>
              setAllTagsShown(showAllTags.target.checked)
            }
            checked={allTags}
          />
        </div>
      </div>
      {show && (
        <Modal
          open={show}
          onClose={toggleModal(false)}
          contentLabel="Quick filters">
          <QuickFilterForm
            toggleModal={toggleModal}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </Modal>
      )}
    </div>
  );
};
