import gql from 'graphql-tag';
import { elasticIndex, taxonomyIndex } from 'utils/constants';

export const fetchElasticCount = gql`
  query elasticCount {
    elasticData(input: $input) @rest(type: "[Data]", path: "${elasticIndex}/_count", method: "POST") {
      count
    }
  }
`;

export const fetchElasticData = gql`
  query elasticData {
    elasticData(input: $input) @rest(type: "[Data]", path: "${elasticIndex}/_search", method: "POST") {
      hits
      aggregations
    }
  }
`;

// TODO: merge these
export const fetchElasticTaxonomy = gql`
  query elasticData {
    elasticData(input: $input) @rest(type: "[Data]", path: "${taxonomyIndex}/_search", method: "POST") {
      hits
    }
  }
`;

export const fetchElasticAutoComplete = gql`
  query elasticData {
    elasticData(input: $input) @rest(type: "[Data]", path: "${elasticIndex}/_search", method: "POST") {
      suggest
    }
  }
`;
