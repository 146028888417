import gql from 'graphql-tag';
import { quickFilterFragment } from './fragments/quickFilterFragment';

// TODO: change user id to current user
export const fetchQuickFilters = gql`
  query FetchQuickFilters($userId: String!) {
    quickFilterQuery(
      limit: 100
      filter: {
        conditions: [{ operator: EQUAL, field: "user_id", value: [$userId] }]
      }
    ) {
      entities {
        ...QuickFilter
      }
    }
  }
  ${quickFilterFragment}
`;
