// Import Environment variables from .ecs file, managed by Devops
// Include default values for production in case .env file is not present
// Defaults can possibly be removed in future once all .ecs files are in place,
// to make it harder for hackers to find keys

export const graphqlApiURL =
  process.env.REACT_APP_GQL_API_URL || 'stage.paymentscompliance.vixio.com';

export const restApiURL = process.env.REACT_APP_ECS_API_URL || 'es.vixio.com';

export const elasticIndex =
  process.env.REACT_APP_ECS_ELASTIC_INDEX ||
  'elasticsearch_index_stagebackend_global_search';

export const taxonomyIndex =
  process.env.REACT_APP_ECS_TAX_INDEX ||
  'elasticsearch_index_stagebackend_taxonomy';

export const restApiAuthKey =
  process.env.REACT_APP_ECS_API_AUTH_KEY ||
  'cmVhY3Q6NTdmYXBPVmJSbDNCOTNGOUo2NGd2Nm5KRmFaQXMwRkc=';

export const oAuthClientId =
  process.env.REACT_APP_OAUTH_CLIENTID ||
  '6294b2ad-736a-4cf4-9b24-dd510537c14d';

export const beProtocol = process.env.REACT_APP_GQL_API_PROTOCOL || 'https';

// Setup endpoints

export const graphqlApi = `${beProtocol}://${graphqlApiURL}/graphql?_format=json`;
export const oauthUrl = `${beProtocol}://${graphqlApiURL}/oauth/token?_format=json`;
export const restApi = `${beProtocol}://${restApiURL}/`;
export const restApiProxy = `${beProtocol}://${graphqlApiURL}/es/`;
export const resetPasswordUrl = `${beProtocol}://${graphqlApiURL}/user/lost-password-reset?_format=json`;
export const forgottenPasswordUrl = `${beProtocol}://${graphqlApiURL}/user/lost-password-pardot?_format=json`;
export const restApiAuth = `Basic ${restApiAuthKey}`;

// Misc

export const resultSize = { size: 10000 };

export const regUpdates = '01t3X00000FydxsQAB';
export const all = '01t3X00000Fydy2QAB';
export const guides = '01t3X00000FyePIQAZ';
export const trackers = '01t3X00000FyePNQAZ';
export const articles = '01t3X00000FyswEQAR';
export const all_hs = '01t3X00000GZIsJQAX';

export const permissionsConfig = {
  articles: [articles, all, all_hs],
  regUpdates: [regUpdates, all],
  guides: [guides, all, all_hs],
  trackers: [trackers, all, all_hs]
};

export const short = 'You do not have access to this content.';

export const medium = 'You do not have access to this content.';

export const long = 'You do not have access to this content.';

export const noContent = `Unfortunately it seems your company's security settings may be blocking the content from loading. Please contact your account manager or your company's tech support team and request to unblock https://es.vixio.com.`;

export const pageVocabExclusions = {
  home: ['document_types', 'companies', 'requirement_categories', 'licences'],
  insights: [
    'document_types',
    'companies',
    'requirement_categories',
    'licences'
  ],
  regulatory_analysis: ['companies', 'requirement_categories', 'licences'],
  guide: ['document_types', 'companies', 'requirement_categories', 'licences'],
  regulatory_updates: ['companies', 'requirement_categories', 'licences'],
  tracker_topics: [
    'tracker_topics',
    'companies',
    'requirement_categories',
    'licences'
  ],
  requirement: [
    'authorities',
    'document_types',
    'specialisms',
    'featured_topics',
    'tracker_topics',
    'companies'
  ]
};

// TODO use these routes in App.jsx routes array config
export const routes = {
  home: '/home',
  regUpdates: '/regulatory_updates/feeds',
  regUpdate: '/regulatory_update/:regupdateId',
  articles: '/articles',
  regAnalysis: '/regulatory_analysis',
  article: '/article/:articleId',
  trackers: '/tracker_topics',
  guides: '/guide',
  guide: '/guide/:guideId',
  setting: '/settings/:option',
  search: '/search',
  author: '/author/:authorId',
  requirement: '/requirementone/:requirementId?',
  rules: '/rule',
  term: '/:vocabulary/:tagId/:parentName/:tagName',
  term2: '/:vocabulary/:tagId/:tagName',
  term3: '/:vocabulary/:tagId/'
};

export const filterConfig = {
  [routes.home]: {
    hideViewSelector: true
  },
  [routes.regUpdates]: {
    hideFilters: false
  },
  [routes.regUpdate]: {
    hideFilters: true
  },
  [routes.articles]: {
    hideFilters: false
  },
  [routes.article]: {
    hideFilters: true
  },
  [routes.trackers]: {
    hideFilters: true
  },
  [routes.term]: {
    hideViewSelector: true
  },
  [routes.term2]: {
    hideViewSelector: true
  },
  [routes.term3]: {
    hideViewSelector: true
  },
  [routes.guides]: {
    hideViewSelector: true
  },
  [routes.guide]: {
    hideViewSelector: true
  },
  [routes.setting]: {
    hideFilters: true
  },
  [routes.search]: {
    hideViewSelector: true
  },
  [routes.author]: {
    hideViewSelector: true
  },
  [routes.rules]: {
    hideFilters: true
  },
  [routes.rule]: {
    hideFilters: true
  },
  [routes.requirement]: {
    hideFilters: true
  }
};

// PDF settings

export const logo = '/images/assets/logo-payments.svg';
export const legalSignature =
  'All rights reserved. ©Compliance Online Limited (trading as VIXIO). St. Clare House, 30 Minories, London, EC3N 1DD.';

export const matomoHost =
  process.env.REACT_APP_MATOMO_HOST || 'analytics.vixio.support';
export const matomoSiteId = process.env.REACT_APP_MATOMO_SITE_ID || 2;

export const sentryEnable = process.env.REACT_APP_SENTRY_ENABLE || 0;

export const basicPageTermsAndCondition = 62115;

export const basicPageAntiSlaveryPolicy = 62237;

export const basicPageInsights = 67234;
export const basicPageRegulatoryAnalysis = 67235;
export const basicPageGuides = 67236;
export const basicPageRequirements = 67237;
export const basicPageHorizonScanning = 67238;
export const basicPageTrackers = 67239;
