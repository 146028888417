/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

export const Input = ({
  type,
  isInvalid,
  value,
  onChange,
  className = '',
  name,
  errorMessage,
  label
}) => {
  const message = !errorMessage ? `Please enter a ${name}` : errorMessage;

  return (
    <li className={type === 'checkbox' ? 'form__checkbox' : ''}>
      {isInvalid && <p className="form__error__message">{message}</p>}

      <label htmlFor={name}>{label || name}</label>
      <input
        id={name}
        type={type}
        className={className}
        value={value}
        onChange={onChange}
        required
        autoComplete="off"
        placeholder={`Enter ${name}`}
      />
    </li>
  );
};
