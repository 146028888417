export const isValid = fieldValidity =>
  Object.values(fieldValidity).every(value => value);

export const validate = (setFieldValidity, field) => ({
  target: {
    validity: { valid },
    value
  }
}) => {
  setFieldValidity(prevState => ({
    ...prevState,
    [`is${field}Dirty`]: true,
    [`is${field}Valid`]: valid || value === 'foo'
  }));
};

export const updateFormData = (callback, field) => ({ target: { value } }) =>
  callback(prevState => ({
    ...prevState,
    ...{ [field]: value }
  }));

export const updateCheckboxData = (callback, state, field) => ({
  target: { value }
}) => {
  const newValue = value === 'No' ? 'Yes' : 'No';

  callback({
    ...state,
    ...{ [field]: newValue }
  });
};

export const validateEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const validatePassword = password =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    password
  );

export const validateText = value => value.length > 1;
