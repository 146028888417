import React, { useEffect, useState } from 'react';
import { debounce } from 'utils/utils';

export const BackToTop = () => {
  const [isActive, showToTop] = useState(false);

  const showScroll = debounce(() => {
    const y = window.scrollY;
    showToTop(y > 0);
  }, 100);

  const toTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', showScroll);

    return () => {
      window.removeEventListener('scroll', showScroll);
    };
  });

  return (
    <div
      className={`back-to-top ${isActive ? 'active' : ''}`}
      onClick={toTop}
    />
  );
};
