import React, { useEffect } from 'react';
import { setNavStatus } from 'api/navStatus';
import { SearchBarGlobal } from 'components/Search/SearchBarGlobal';
import { AccountOptions } from 'components/AccountOptions/AccountOptions';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'utils/utils';

// This doesn't need to be state. It's not used anywhere in this component.
let menuOpen = !isMobile();
let historyListener = null;
const toggleMenu = state => {
  menuOpen = state;
};
export const Menu = () => {
  const history = useHistory();

  useEffect(() => {
    if (isMobile()) {
      historyListener = history.listen((location, action) => {
        if (action === 'PUSH') {
          // route change
          setNavStatus(false);
          toggleMenu(false);
        }
      });
    }
    return function cleanup() {
      if (historyListener !== null) {
        historyListener();
        historyListener = null;
      }
    };
  }, [history]);

  const adjustMenu = () => {
    setNavStatus(!menuOpen);
    toggleMenu(!menuOpen);
  };

  useEffect(() => {
    if (isMobile()) setNavStatus(false);
  }, []);

  return (
    <div className="compliance__menu">
      <div
        className="compliance__menu__burger"
        onClick={adjustMenu}
        onKeyPress={adjustMenu}
        tabIndex={0}
        role="button">
        <div className="compliance__menu__burger__bar" />
        <div className="compliance__menu__burger__bar" />
        <div className="compliance__menu__burger__bar" />
      </div>
      <Link className="vixio__logo" to="/home">
        VIXIO
      </Link>

      <div className="compliance__menu__rhs">
        <div className="compliance__menu__rhs__search">
          <SearchBarGlobal />
        </div>
        <AccountOptions />
      </div>
    </div>
  );
};
