import gql from 'graphql-tag';
import * as ApolloReactHooks from '@apollo/react-hooks';

export const getInitialsQuery = gql`
  query getInitials {
    initials @client {
      value
    }
  }
`;

export const setInitialsQuery = gql`
  mutation setInitials($initials: String!) {
    setInitials(initials: $initials) @client
  }
`;

export function useGetInitialsQuery(baseOptions) {
  return ApolloReactHooks.useQuery(getInitialsQuery, baseOptions);
}

export function useSetInitialsMutation(baseOptions) {
  return ApolloReactHooks.useMutation(setInitialsQuery, baseOptions);
}

export const setInitials = (cache, value) =>
  cache.writeData({ data: { initials: { __typename: 'Initials', value } } });
