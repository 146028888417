import React, { useState } from 'react';
import { checkedTerms, overwriteActiveFilters } from 'api/activeFilters';
import PropTypes from 'prop-types';
import { pageVocabExclusions } from 'utils/constants';
import { useQuery } from '@apollo/react-hooks';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FilterCheckbox } from './FilterCheckbox';

export const VocabularyDropdown = ({ vocabulary, options }) => {
  const { trackEvent } = useMatomo();
  const [activeDropdown, toggleDropdown] = useState(false);

  const dropdownHandler = () => {
    toggleDropdown(!activeDropdown);
    trackEvent({
      category: 'VocabularyDropdown',
      action: 'toogleDropdown',
      name: vocabulary,
      value: !activeDropdown ? 1 : 0
    });
  };

  const checkAuthorities = globalFilters => {
    const activeJurisdictionIds =
      globalFilters &&
      globalFilters
        .filter(({ vid }) => vid === 'jurisdictions')
        .map(({ tid }) => tid);
    return options.filter(({ tid }) => activeJurisdictionIds.includes(tid));
  };

  // certain pages shouldn't show certain filter options so filter these out
  const [path] = window.location.pathname
    .replace('/', '')
    .replace('-', '_')
    .split('/');
  const exclusions = pageVocabExclusions[path.toLowerCase()] || [];

  const { data, error, loading } = useQuery(checkedTerms);
  if (error) {
    return 'Oops!';
  }
  if (loading) {
    return 'Loading...';
  }
  const { globalFilters } = data;
  const activeIds = globalFilters && globalFilters.map(filter => filter.tid);

  const extractData = (dataItem, level) => {
    if (dataItem.children === undefined) {
      return {};
    }
    // Update status based on children.
    let countChildren = 0;
    const children =
      dataItem.children.length > 0
        ? dataItem.children
            .sort((a, b) => a.weight - b.weight)
            .map(d => extractData(d, level + 1))
        : [];
    children.forEach(c => {
      countChildren += c.count;
    });
    const count = dataItem.count ? dataItem.count : 0;
    countChildren += count;

    const oneUnchecked = children.findIndex(
      d => d.status === 'unchecked' && d.countChildren > 0
    );
    const oneChecked = children.findIndex(
      d => d.status === 'checked' && d.countChildren > 0
    );
    const oneIndeterminate = children.findIndex(
      d => d.status === 'indeterminate' && d.countChildren > 0
    );
    const active = activeIds.indexOf(dataItem.tid);

    let status = 'indeterminate';
    if (active >= 0 && oneUnchecked === -1 && oneIndeterminate === -1) {
      status = 'checked';
    }
    if (active === -1 && oneChecked === -1 && oneIndeterminate === -1) {
      status = 'unchecked';
    }

    let authorityRoot = false;
    if (
      (vocabulary === 'authorities' || vocabulary === 'licences') &&
      level === 0
    ) {
      status = 'unchecked';
      authorityRoot = true;
      countChildren -= count;
    }

    return {
      tid: dataItem.tid,
      name: dataItem.name,
      level,
      count,
      countChildren,
      status,
      children,
      authorityRoot
    };
  };

  const items = (vocabulary === 'authorities' || vocabulary === 'licences'
    ? checkAuthorities(globalFilters)
    : options
  )
    .sort((a, b) => a.weight - b.weight)
    .map(d => extractData(d, 0));

  const extractChildren = (extractItems, children) => {
    for (let i = 0; i < extractItems.length; i += 1) {
      if (extractItems[i].countChildren > 0) {
        children.push(extractItems[i]);
        if (extractItems[i].children.length > 0) {
          extractChildren(extractItems[i].children, children);
        }
      }
    }
  };

  /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["result"] }] */
  const extractRelations = (extractItems, tid, parents, result) => {
    for (let i = 0; i < extractItems.length; i += 1) {
      if (extractItems[i].tid === tid) {
        const children = [];
        extractChildren(extractItems[i].children, children);

        result.parents = parents;
        result.tid = extractItems[i].tid;
        result.name = extractItems[i].name;
        result.children = children;
        result.authorityRoot = extractItems[i].authorityRoot;
        return;
      }

      if (extractItems[i].children.length > 0) {
        extractRelations(
          extractItems[i].children,
          tid,
          [...parents, extractItems[i]],
          result
        );
      }
    }
  };

  const addFilter = (tid, name, newFilters) => {
    for (let i = 0; i < newFilters.length; i += 1) {
      if (newFilters[i].tid === tid) {
        return;
      }
    }
    newFilters.push({
      name,
      vid: vocabulary,
      tid,
      __typename: 'filter',
      active: true
    });
  };

  const updateSelection = tid => {
    let newFilters = [...globalFilters];
    const active = activeIds.indexOf(tid);
    const relations = {};
    extractRelations(items, tid, [], relations);
    if (relations.authorityRoot) {
      return;
    }

    if (active === -1) {
      // If not checked. Check it and all of its children
      addFilter(relations.tid, relations.name, newFilters);

      relations.children.forEach(c => addFilter(c.tid, c.name, newFilters));
    } else {
      // If checked. Unchek it and all of its children + parents
      const idsToRemove = [];
      idsToRemove.push(tid);
      for (let i = 0; i < relations.children.length; i += 1) {
        idsToRemove.push(relations.children[i].tid);
      }
      for (let i = 0; i < relations.parents.length; i += 1) {
        if (!relations.parents[i].authorityRoot) {
          idsToRemove.push(relations.parents[i].tid);
        }
      }
      newFilters = newFilters.filter(f => idsToRemove.indexOf(f.tid) === -1);
    }
    overwriteActiveFilters(newFilters);
  };

  const dropdownActive = activeDropdown ? 'active' : '';
  let vocabularyName = vocabulary.replace(/_/g, ' ');
  if (vocabularyName === 'licences') {
    vocabularyName = 'licence names';
  }
  if (!exclusions.includes(vocabulary)) {
    return (
      <div className="vocab-dropdown">
        <div
          className="vocab-dropdown__parent"
          onClick={dropdownHandler}
          onKeyPress={dropdownHandler}
          tabIndex={0}
          role="button">
          {vocabularyName}
          <i className={`vocab-dropdown__arrow-dropdown ${dropdownActive}`} />
        </div>
        <div className={`vocab-dropdown__inner ${dropdownActive}`}>
          {items.length ||
          (vocabulary !== 'authorities' && vocabulary !== 'licences') ? (
            items
              .filter(item => item.countChildren > 0)
              .map(item => (
                <FilterCheckbox
                  key={`${vocabulary}-${item.tid}`}
                  tid={item.tid}
                  vocabulary={vocabulary}
                  name={item.name}
                  status={item.status}
                  childrenTerms={item.children}
                  updateSelection={updateSelection}
                />
              ))
          ) : (
            <p>
              Please select a Jurisdiction to display its{' '}
              {vocabulary === 'authorities' ? 'authorities' : 'licence names'}.
            </p>
          )}
        </div>
      </div>
    );
  }
  return null;
};

VocabularyDropdown.propTypes = {
  vocabulary: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      tid: PropTypes.number,
      parent: PropTypes.number,
      count: PropTypes.number
    })
  )
};

VocabularyDropdown.defaultProps = {
  vocabulary: '',
  options: [{ tid: 0, vocabulary: '', taxonomy: '' }]
};
