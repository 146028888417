import React, { useState, useEffect } from 'react';
import { overwriteActiveFilters } from 'api/activeFilters';

export const QuickFilter = ({ name: filterName, fieldFilters }) => {
  const [filters, updateLocalFilters] = useState([]);

  useEffect(() => {
    const formattedFilters = fieldFilters
      .filter(({ entity }) => entity)
      .map(({ targetId: tid, entity: { name, vid: { targetId: vid } } }) => ({
        name,
        vid,
        tid
      }));
    updateLocalFilters(formattedFilters);
  }, [fieldFilters]);

  const applyFilters = () => {
    overwriteActiveFilters(filters);
  };

  return (
    <div className="individual-quick-filter" onClick={applyFilters}>
      {filterName}
    </div>
  );
};
