import gql from 'graphql-tag';

export const quickFilterFragment = gql`
  fragment QuickFilter on QuickFilter {
    id
    name
    fieldEmail
    fieldEmailPreferences
    fieldType
    fieldFePath
    fieldTemplate
    fieldTemplateId
    userId {
      targetId
    }
    fieldFilters {
      targetId
      entity {
        name
        vid {
          targetId
        }
      }
    }
  }
`;
