import React, { useState } from 'react';
import { isValid, validate, updateFormData } from 'utils/form';
import { handleResponse, handleForgottenPassword } from 'api/authentication';
import { InputEmail as Email } from '../Form/InputEmail';

export const ForgottenPasswordForm = ({ showForm }) => {
  const [formValues, setFormValues] = useState({});
  const [fieldValidity, setFieldValidity] = useState({
    isEmailDirty: false,
    isEmailValid: false
  });
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const handleInput = field => {
    const stateHandler = updateFormData(setFormValues, field);
    return e => {
      setErrorMessage(null);
      stateHandler(e);
    };
  };

  const submit = async ({ key }) => {
    if (key && key !== 'Enter') return;
    if (isValid(fieldValidity)) {
      try {
        const response = await handleForgottenPassword(formValues);
        const responseMessage = await handleResponse(response);
        if (responseMessage.message) setMessage(responseMessage.message);
      } catch (err) {
        if (err.message) setErrorMessage(err.message);
      }
    }
  };
  const { username } = formValues;
  const { isEmailDirty, isEmailValid } = fieldValidity;

  return (
    <div className="login__form forgotten-password">
      <h1 className="login__title vixio__logo">VIXIO</h1>

      {message ? (
        <>
          <p>Thank you. {message}</p>

          <p className="login__form__link" onClick={showForm()}>
            Return to log in
          </p>
        </>
      ) : (
        <>
          {errorMessage && (
            <p className="form__error__message">{errorMessage}</p>
          )}

          <Email
            label="Username"
            className="form__field"
            isInvalid={isEmailDirty && !isEmailValid}
            value={username}
            onChange={handleInput('email')}
            onKeyUp={validate(setFieldValidity, 'Email')}
            onKeyPress={submit}
          />

          <button
            type="button"
            className="button button--primary"
            onClick={submit}
            disabled={!isEmailValid}>
            Request New Password
          </button>

          <p className="login__form__link" onClick={showForm()}>
            Return to log in
          </p>
        </>
      )}
    </div>
  );
};
