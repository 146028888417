import gql from 'graphql-tag';

export const typeDefs = gql`
  directive @client on FIELD
  type AuthState {
    state: String
  }
  type Initials {
    value: String
  }
  type Mutation {
    setAuthState(status: String!): AuthState
  }
  extend type Query {
    isMaintenanceMode: Boolean!
    isShowAllTagsMode: Boolean!
    isServerError: Boolean!
    authState: AuthState
    initials: Initials
  }
`;

export const resolvers = {
  Mutation: {
    setInitials: (_, initials, { cache }) => {
      cache.writeData({
        data: {
          __typename: 'Initials',
          initials
        }
      });
    },
    setAuthState: (_, { status }, { cache }) => {
      const data = {
        authState: {
          __typename: 'AuthState',
          state: status
        }
      };
      cache.writeData({ data });
      return null;
    },
    // @ts-ignore
    createQuickFilter: () => {},
    // @ts-ignore
    deleteQuickFilter: () => {},
    // @ts-ignore
    updateEmailPreferences: () => {},
    // @ts-ignore
    updateQuickFilter: () => {}
  }
};
