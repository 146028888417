import React from 'react';
import PropTypes from 'prop-types';
import { getRecentSearches } from 'utils/storage';

const recentSearches = getRecentSearches();

export const RecentSearches = ({ makeSearch }) => {
  return (
    recentSearches &&
    recentSearches.length && (
      <div className="searchbar__dropdown__recent-searches">
        <h5 className="searchbar__dropdown__header">Your recent searches</h5>
        <ul className="searchbar__dropdown__recent">
          {Object.values(recentSearches)
            .reverse()
            .map(value => (
              <li
                className="searchbar__dropdown__recent-item"
                name={value}
                onClick={() => makeSearch(value)}
                key={value}>
                {value}
              </li>
            ))}
        </ul>
      </div>
    )
  );
};

RecentSearches.propTypes = {
  makeSearch: PropTypes.func
};

RecentSearches.defaultProps = {
  makeSearch: () => {}
};
