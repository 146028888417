import React from 'react';
import { getActiveView, setActiveView } from 'api/client/activeView';
import PropTypes from 'prop-types';
import { imageRoot } from 'utils/utils';
import { useQuery } from '@apollo/react-hooks';

export const ViewIcon = ({ type }) => {
  const changeView = () => {
    setActiveView(type);
  };

  const {
    data: {
      currentView: { name }
    }
  } = useQuery(getActiveView);
  const active = name === type ? 'active' : '';
  return (
    <div
      className="view-icon"
      onClick={changeView}
      onKeyPress={changeView}
      tabIndex={0}
      role="button">
      <img className={active} src={imageRoot(`${type}.svg`)} alt={type} />
    </div>
  );
};

ViewIcon.propTypes = {
  type: PropTypes.string
};

ViewIcon.defaultProps = {
  type: 'list'
};
