import React, { useState } from 'react';
import { handleResponse, handleResetPassword } from 'api/authentication';
import { isValid, validate, updateFormData } from 'utils/form';
import { InputPassword as Password } from '../Form/InputPassword';

export const ResetPasswordForm = ({ showForm }) => {
  const [passwords, updatePassword] = useState({
    password: '',
    confirmPassword: ''
  });

  const [error, errorHandler] = useState({
    errorMessage: ''
  });

  const [fieldValidity, setFieldValidity] = useState({
    isPasswordDirty: false,
    isConfirmPasswordDirty: false,
    isPasswordValid: false,
    isConfirmPasswordValid: false
  });

  const [successMessage, updateStatus] = useState({
    message: ''
  });

  const getUrlParams = search => {
    const urlParams = new URLSearchParams(search);
    const params = {
      user: urlParams.get('user'),
      token: urlParams.get('token')
    };
    return params;
  };

  const submitResetPassword = async ({ key }) => {
    if (key && key !== 'Enter') return;

    const { password, confirmPassword } = passwords;

    if (password !== confirmPassword) {
      errorHandler({ errorMessage: 'Passwords do not match' });
      return;
    }

    if (isValid(fieldValidity)) {
      try {
        const { token, user } = getUrlParams(window.location.search);
        const body = {
          name: user,
          temp_pass: token,
          new_pass: password
        };
        const response = await handleResetPassword(body);
        const message = await handleResponse(response);

        updateStatus(message);
      } catch ({ status }) {
        const errorText =
          status === 400
            ? 'Your login link is invalid.'
            : 'An error has occurred.';
        const errorMessage = `${errorText} Please request a new password using the link below.`;

        errorHandler({ errorMessage });
      }
    }
  };

  const { password, confirmPassword } = passwords;
  const { errorMessage } = error;
  const {
    isPasswordDirty,
    isConfirmPasswordDirty,
    isPasswordValid,
    isConfirmPasswordValid
  } = fieldValidity;
  const { message } = successMessage;

  return (
    <div className="login__form">
      <h1 className="login__title vixio__logo">VIXIO</h1>
      {message ? (
        <>
          <p>Thank you. {message}</p>
          <button
            type="button"
            className="button button--primary"
            onClick={showForm()}>
            Click here to log in
          </button>
        </>
      ) : (
        <>
          {errorMessage && (
            <p className="form__error__message">{errorMessage}</p>
          )}

          <p>Please enter your new password below:</p>
          <form>
            <Password
              label="Password"
              className="login__form__field form__field"
              isInvalid={isPasswordDirty && !isPasswordValid}
              value={password}
              onChange={updateFormData(updatePassword, 'password')}
              onKeyUp={validate(setFieldValidity, 'Password')}
              onKeyPress={submitResetPassword}
            />

            <Password
              label="Confirm Password"
              className="form__field"
              isInvalid={isConfirmPasswordDirty && !isConfirmPasswordValid}
              value={confirmPassword}
              onChange={updateFormData(updatePassword, 'confirmPassword')}
              onKeyUp={validate(setFieldValidity, 'ConfirmPassword')}
              onKeyPress={submitResetPassword}
              placeholder="Confirm password"
            />

            <button
              type="button"
              className="button button--primary"
              onClick={submitResetPassword}
              disabled={!(isPasswordValid && isConfirmPasswordValid)}>
              Reset Password
            </button>
          </form>
          <p className="login__form__link" onClick={showForm('forgotten')}>
            Request password reset
          </p>
        </>
      )}
    </div>
  );
};
