import React, { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { navStatus } from 'api/navStatus';
import { clearActiveFilters } from 'api/activeFilters';
import { QuickFilterSection } from 'components/Nav/QuickFilterSection';
import { hideFilterElements } from 'utils/utils';
import { useQuery } from '@apollo/react-hooks';
import { GlobalFilterNav } from './GlobalFilterNav';

const QuickFiltersNav = () => {
  const location = useLocation();
  const { hideFilters } = hideFilterElements(location.pathname);
  if (hideFilters) return null;
  return (
    <>
      <GlobalFilterNav />
      <QuickFilterSection />
    </>
  );
};

const NavInner = memo(() => {
  const isChildRoute = ([first, second]) => (match, { pathname }) =>
    pathname.includes(first) || pathname.includes(second);
  return (
    <>
      <ul className="main-nav__pages">
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/home'
            }}
            exact>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/insights'
            }}
            isActive={isChildRoute(['/insights', '/insights'])}>
            Insights
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/regulatory_analysis'
            }}
            exact
            isActive={isChildRoute([
              '/regulatory_analysis',
              '/regulatory-analysis'
            ])}>
            Regulatory Analysis
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/guide'
            }}>
            Guides
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/requirement'
            }}>
            Requirements
          </NavLink>
        </li>
        {/* <li> */}
        {/*  <NavLink */}
        {/*    activeClassName="active" */}
        {/*    onClick={clearActiveFilters} */}
        {/*    to={{ */}
        {/*      pathname: '/rule' */}
        {/*    }}> */}
        {/*    Rules */}
        {/*  </NavLink> */}
        {/* </li> */}
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/regulatory_updates/feeds'
            }}
            isActive={isChildRoute(['/regulatory_update'])}>
            Horizon Scanning
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            onClick={clearActiveFilters}
            to={{
              pathname: '/tracker_topics'
            }}
            isActive={isChildRoute(['/tracker_topics', '/tracker-topics'])}>
            Trackers
          </NavLink>
        </li>
      </ul>
      <QuickFiltersNav />
    </>
  );
});

export const Nav = () => {
  const {
    data: {
      navStatus: { status }
    }
  } = useQuery(navStatus);
  return (
    <div className={`main-nav ${status ? 'open' : 'closed'}`}>
      <NavInner />
    </div>
  );
};
