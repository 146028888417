import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import Cookie from 'js-cookie';
import { useGetAuthStateQuery } from '../../api/client/authState';
import { LazyRoute } from './LazyRoute';

export const LoggedOutLayoutRoute = props => {
  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  if (location.search.includes('user') && location.search.includes('token')) {
    Cookie.remove('access_token');
    Cookie.remove('refresh_token');
    Cookie.remove('client_id');
    client.writeData({
      data: { authState: { state: 'loggedOut', __typename: 'AuthState' } }
    });
  }
  const {
    data: {
      authState: { state: authState }
    }
  } = useGetAuthStateQuery();
  if (authState !== 'loggedOut') history.push('/');
  return <LazyRoute {...props} />;
};
