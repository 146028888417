// import fetch from 'utils/fetch';
import {
  oauthUrl,
  forgottenPasswordUrl,
  resetPasswordUrl
} from 'utils/constants';
import { client } from 'api/apolloClient';
import jsonp from 'better-jsonp';

export const handleOauth = details => {
  const body = Object.entries(details)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  return fetch(oauthUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body
  });
};

export const handleResponse = response => {
  if (response.status === 503) {
    client.writeData({ data: { isMaintenanceMode: true } });
  } else if (response.status === 500) {
    client.writeData({ data: { isServerError: true } });
  } else {
    const clone = response.clone();

    return response
      .json()
      .then(json => {
        if (!response.ok) {
          const error = new Error(json.message);
          error.status = response.status;
          error.statusText = response.statusText;
          return Promise.reject(error);
        }
        return json;
      })
      .catch(() => {
        return clone.json().then(json => {
          const error = new Error(json.message);
          error.status = response.status;
          error.statusText = response.statusText;
          return Promise.reject(error);
        });
      });
  }
};

export const handleForgottenPassword = ({ email }) => {
  return fetch(forgottenPasswordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      mail: email
    })
  });
};

export const handleResetPassword = body => {
  return fetch(resetPasswordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
};

export const handleContactForm = fields => {
  return jsonp({
    url: `https://go.pardot.com/l/77152/2019-10-21/8lp17j`,
    // global function named `callback` will be invoked when JSONP response
    jsonpCallback: 'pardotJsonpCallback', // any different name from request module
    timeout: 5000,
    // eg. ?customCallbackParams=...
    // callbackParams: 'customCallbackParams',
    urlParams: fields
  }).then(data => {
    if (data.result === 'error') {
      return Promise.reject(data);
    }
    return data;
  });
};
