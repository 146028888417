import React, { useMemo, useState } from 'react';
import { fetchElasticTaxonomy } from 'api/elasticRequests/fetchElasticData';
import { clearActiveFilters } from 'api/activeFilters';
import { query as enabledFiltersQuery } from 'api/enabledFilters';
import { fetchParentFilter } from 'api/elasticRequests/fetchGlobalFilter';
import { Preloader } from 'components/common/Preloader';
import { useQuery } from '@apollo/react-hooks';
import { VocabularyDropdown } from './VocabularyDropdown';
import { getEsClient } from '../../utils/utils';

export const GlobalFilterNav = () => {
  const createHierarchies = ({ hits } = {}, enabledFilters = []) => {
    if (hits) {
      const allFilters = hits.map(
        ({
          _source: {
            name: [name],
            parent: [parent],
            field_jurisdiction: [jurisdiction] = [0],
            tid: [tid],
            vid: [vid],
            weight: [weight] = [],
            name_full: [name_full]
          }
        }) => {
          // make sure count is not empty
          const [count] = enabledFilters
            .filter(filt => filt.name === name_full)
            .map(({ count: filterCount }) => filterCount);

          return {
            name,
            parent: jurisdiction || parent,
            tid,
            vid,
            weight,
            count,
            children: []
          };
        }
      );

      // get the parents of the authorities so the jurisdictions can be added
      // as parents visually
      const authoritiesParent = [
        ...new Set(
          allFilters
            .filter(({ vid, parent }) => vid === 'authorities' && parent > 0)
            .map(({ parent }) => parent)
        )
      ];
      const licencesParent = [
        ...new Set(
          allFilters
            .filter(({ vid, parent }) => vid === 'licences' && parent > 0)
            .map(({ parent }) => parent)
        )
      ];

      // get parents for the hierarchy
      let hierarchy = allFilters.filter(({ parent }) => {
        return parent === 0;
      });

      // add the dummy jurisdictions that will sit as the parents of the
      // authorities
      allFilters.forEach(({ name, tid, weight, count, children }) => {
        if (authoritiesParent.includes(tid)) {
          const newFilter = {
            name,
            tid,
            vid: 'authorities',
            parent: 0,
            weight,
            count,
            children
          };
          hierarchy = [...hierarchy, newFilter];
        }
        if (licencesParent.includes(tid)) {
          const newFilter = {
            name,
            tid,
            vid: 'licences',
            parent: 0,
            weight,
            count,
            children
          };
          hierarchy = [...hierarchy, newFilter];
        }
      });

      // get the first tier of filters
      let secondTier = [];
      allFilters.forEach(({ name, parent, tid, vid, weight, count }) => {
        if (parent > 0) {
          const heir = hierarchy.find(
            filter => filter.tid === parent && filter.vid === vid
          );

          if (heir) {
            heir.children = [
              ...heir.children,
              { name, parent, tid, vid, weight, count, children: [] }
            ];
          } else {
            secondTier = [
              ...secondTier,
              { name, parent, tid, vid, weight, count }
            ];
          }
        }
      });

      // go through the next tier of filters and add them to the first ones
      secondTier.forEach(({ name, parent, tid, vid, weight, count }) => {
        hierarchy.forEach(({ children }) => {
          const parentFilter = children.find(
            filter => filter.tid === parent && filter.vid === vid
          );

          if (parentFilter) {
            parentFilter.children = [
              ...parentFilter.children,
              { name, parent, tid, vid, weight, count, children: [] }
            ];
          }
        });
      });

      // squash into easier to use objects
      const labels = [...new Set(hierarchy.map(({ vid }) => vid))];

      const result = {};
      Object.values(labels).forEach(label => {
        result[label] = hierarchy.filter(filter => filter.vid === label);
      });

      return {
        jurisdictions: result.jurisdictions,
        authorities: result.authorities,
        document_types: result.document_types,
        specialisms: result.specialisms,
        products_and_services: result.products_and_services,
        featured_topics: result.featured_topics,
        tracker_topics: result.tracker_topics,
        companies: result.companies,
        licences: result.licences,
        requirement_categories: result.requirement_categories
        // requirement_topics: result.requirement_topics
      };
    }
    return [];
  };

  const [hits, setHits] = useState();
  const [enabledFilters, setEnabledFilters] = useState();

  const clearFilters = () => clearActiveFilters();

  const { data, error, loading } = useQuery(fetchElasticTaxonomy, {
    client: getEsClient(),
    variables: { input: fetchParentFilter }
  });
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading
  } = useQuery(enabledFiltersQuery);
  if (queryData && enabledFilters !== queryData.enabledFilters) {
    setEnabledFilters(queryData.enabledFilters);
  }
  if (
    data &&
    data.elasticData &&
    data.elasticData.hits &&
    hits !== data.elasticData.hits
  ) {
    setHits(data.elasticData.hits);
  }
  const vocabularies = useMemo(() => createHierarchies(hits, enabledFilters), [
    hits,
    enabledFilters
  ]);

  if (loading || queryLoading) {
    return <Preloader inline />;
  }
  if (error || queryError) {
    return 'Oops!';
  }
  return (
    <div className="globalfilter">
      <h3 className="globalfilter__header">Global Filters</h3>
      {Object.keys(vocabularies).map(vocab => {
        return (
          <VocabularyDropdown
            vocabulary={vocab}
            options={vocabularies[vocab]}
            key={vocab}
          />
        );
      })}
      <div className="globalfilter__buttons">
        <button type="button" onClick={clearFilters}>
          Clear
        </button>
      </div>
    </div>
  );
};
