import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Nav } from 'components/Nav/Nav';
import { Menu } from 'components/Menu/Menu';
import Header from 'components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { GlobalFilterBar } from 'components/Header/GlobalFilterBar';
import { checkedTerms } from 'api/activeFilters';
import { BackToTop } from 'components/common/BackToTop';
import { useQuery } from '@apollo/react-hooks';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Preloader } from '../common/Preloader';

export const AppLayoutRoute = ({ component: Component, ...rest }) => {
  const { data: { globalFilters } = {}, error, loading } = useQuery(
    checkedTerms
  );
  if (error) {
    return null;
  }
  if (loading) {
    return <Preloader />;
  }
  return (
    <Route
      render={props => (
        <AppLayout {...rest}>
          <Component filters={globalFilters} {...props} {...rest} />
        </AppLayout>
      )}
    />
  );
};

export const AppLayout = ({ children, userDetails }) => {
  const { trackPageView } = useMatomo();
  const location = useLocation();

  React.useEffect(() => {
    if (userDetails !== undefined) {
      const subscriptions = userDetails.permissions
        .filter(a => !a.fieldTrial)
        .map(a => a.fieldProduct.entity.entityLabel)
        .sort()
        .join(';');
      const trial = userDetails.permissions
        .filter(a => a.fieldTrial)
        .map(a => a.fieldProduct.entity.entityLabel)
        .sort()
        .join(';');

      trackPageView({
        customDimensions: [
          {
            id: 1,
            value:
              userDetails.fieldCompany !== null
                ? userDetails.fieldCompany.entity.entityLabel
                : ''
          },
          {
            id: 2,
            value: `${userDetails.fieldFirstName} ${userDetails.fieldLastName}`
          },
          {
            id: 3,
            value: subscriptions
          },
          {
            id: 4,
            value: trial
          }
        ]
      });
    }
  }, [location.pathname, userDetails]); // eslint-disable-line
  // react-hooks/exhaustive-deps

  return (
    <div className="theme-pc">
      <Menu className="compliance__menu" />
      <Nav className="compliance__main-nav" />
      <div className="compliance__container">
        <Header />
        {/*  <NotificationsBar />  Not ready yet.  */}
        <GlobalFilterBar />
        <div className="compliance__content__container">
          <div className="compliance__content">{children}</div>
          <BackToTop />
        </div>
      </div>
      <Footer />
    </div>
  );
};
