import React from 'react';
import { removeActiveFilter } from 'api/activeFilters';
import PropTypes from 'prop-types';

export const Filter = ({ name, tid, active }) => {
  const removeFilter = () => {
    removeActiveFilter(tid);
  };

  const activeFilter = active ? 'active' : '';
  return (
    <span className="individual-filter">
      <span className={`individual-filter__text ${activeFilter}`}>{name}</span>
      <span
        className="individual-filter__close"
        onClick={removeFilter}
        onKeyPress={removeFilter}
        role="button"
        tabIndex={0}>
        🗙
      </span>
    </span>
  );
};

Filter.propTypes = {
  name: PropTypes.string,
  tid: PropTypes.number
};

Filter.defaultProps = {
  name: '',
  tid: ''
};
