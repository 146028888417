import gql from 'graphql-tag';

export const mutateNewFilter = gql`
  mutation NewFilter($input: QuickFilterInput!) {
    createQuickFilter(input: $input) {
      entity {
        entityId
      }
      errors
      violations {
        message
        code
        path
      }
    }
  }
`;
